


























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Meta } from '@sophosoft/vue-meta-decorator'
import { mapActions, mapGetters } from 'vuex'
import { IClubSearch } from '@/_store/models'

@Component({
  computed: {
    ...mapGetters(['StatePublicClubs', 'StateLoading']),
  },
  methods: {
    ...mapActions(['GetPublicClubs']),
  },
})
export default class PublicClubs extends Vue {
  StatePublicClubs!: any
  StateLoading!: boolean
  GetPublicClubs!: Function

  @Meta
  getMetaInfo() {
    return {
      title: 'Public Clubs',
    }
  }

  search_string: string = ''
  search_string_display: string = ''
  searched_string: boolean = false
  toPage: number = 1
  submitted: boolean = false
  sortOptions = [
    {
      key: 'updated_at',
      value: 'Recently Updated',
    },
    {
      key: 'created_at',
      value: 'Newest',
    },
  ]
  sort_by: string = this.sortOptions[0]['key']
  club_search: IClubSearch = {
    sort_by: this.sort_by,
    toPage: this.toPage,
    search: this.search_string,
  }

  get loading() {
    return this.StateLoading
  }

  get clubs() {
    return this.StatePublicClubs
  }
  getPublicClubs() {
    if (this.searched_string) {
      this.search_string_display = this.search_string
    }
    return this.GetPublicClubs({
      sort_by: this.sort_by,
      page: this.toPage,
      search: this.search_string,
    })
  }

  search() {
    if (
      (this.search_string !== '' ||
        this.search_string !== undefined ||
        this.search_string !== null) &&
      this.search_string.length >= 4
    ) {
      this.toPage = 1
      this.searched_string = true
      this.getPublicClubs()
    } else {
      return
    }
  }

  subPage() {
    if (this.clubs.current_page === 1) {
      return
    } else {
      this.toPage = this.clubs.current_page -= 1
      this.getPublicClubs()
    }
  }

  advPage() {
    if (this.clubs.current_page === this.clubs.total_pages) {
      return
    } else {
      this.toPage = this.clubs.current_page += 1
      this.getPublicClubs()
    }
  }

  reset() {
    this.search_string = ''
    this.searched_string = false
    this.getPublicClubs()
  }

  mounted() {
    this.getPublicClubs()
  }
}
