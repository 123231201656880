import Vue from 'vue'
import Vuex from 'vuex'
import { IEnvironmentState } from './modules'
// import { ICustomerState } from './modules/customer'
// import { IFinancingState } from './modules/financing'

Vue.use(Vuex)

export interface IRootState {
  environment: IEnvironmentState
  //   customer: ICustomerState
  // financing: IFinancingState
}

export default new Vuex.Store<IRootState>({})
