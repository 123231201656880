import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from '@/_store/modules/auth'
import noAuth from '@/_store/modules/no-auth'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    noAuth,
  },
  plugins: [createPersistedState()],
})
