






























































































































































































































































import { IMyProfile } from '@/_store/models'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import { IAlert } from '@/types'
import { Meta } from '@sophosoft/vue-meta-decorator'

@Component({
  computed: {
    ...mapGetters(['StateLoading', 'StateMyProfile']),
  },
  methods: {
    ...mapActions(['GetMyProfile', 'UpdateMyProfile']),
  },
})
export default class MyProfile extends Vue {
  @Meta
  getMetaInfo() {
    return {
      title: 'My Profile',
    }
  }

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  StateLoading!: boolean
  StateMyProfile!: IMyProfile
  GetMyProfile!: Function
  UpdateMyProfile!: Function

  fieldTypes: any = {
    password: 'text',
  }

  submitted = false
  errors: any[] = []

  get loading() {
    return this.StateLoading
  }

  get profile() {
    return this.StateMyProfile
  }

  async getProfile() {
    await this.GetMyProfile()
  }

  get widgetId() {
    const post = this.profile.profile_post
    let id: string | number = post.id || ''
    if (post) {
      if (
        (post.visibility === 'clubs_only' || post.visibility === 'private') &&
        post.embed_id
      ) {
        id = post.embed_id
      }
    }
    return id
  }

  get isMobile() {
    const yes = this.$isMobile()
    return yes
  }

  convertToPasswordField(event: any) {
    const { srcElement, type } = event
    const { value } = srcElement

    if (type === 'blur' && !value) {
      srcElement.setAttribute('type', 'text')
    } else {
      srcElement.setAttribute('type', 'password')
    }
  }

  checkForm() {
    this.errors = []

    if (this.profile.full_name == '') {
      this.errors.push('Full Name is required')
    }

    if (this.profile.email == '') {
      this.errors.push('Email is required')
    }

    if (
      (this.profile.password != undefined ||
        this.profile.password_confirmation != undefined) &&
      this.profile.password != this.profile.password_confirmation
    ) {
      this.errors.push('Password and confirmation do not match')
    }

    if (
      (this.profile.account_password != undefined ||
        this.profile.account_password_confirmation != undefined) &&
      this.profile.account_password !=
        this.profile.account_password_confirmation
    ) {
      this.errors.push('Account Password and confirmation do not match')
    }

    return this.errors.length == 0
  }

  async submit() {
    if (
      this.profile.password == '' ||
      this.profile.password_confirmation == ''
    ) {
      this.profile.password = null
      this.profile.password_confirmation = null
    }

    if (
      this.profile.account_password == '' ||
      this.profile.account_password_confirmation == ''
    ) {
      this.profile.account_password = null
      this.profile.account_password_confirmation = null
    }

    if (this.checkForm()) {
      this.submitted = true
      await this.UpdateMyProfile(this.profile)
      const alert: IAlert = {
        message: 'Profile Updated',
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
      this.submitted = false
    } else {
      const alert: IAlert = {
        message: 'Please fix the errors in your form',
        alertType: 'danger',
        timeout: 5000,
      }
      this.sendAlertMessageUp(alert)
      this.$scrollToTop()
    }
  }

  mounted() {
    this.getProfile()
  }
}
