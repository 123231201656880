





















































































































































































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Landing extends Vue {
  get useMailChimp() {
    return this.$useMailChimp
  }
}
