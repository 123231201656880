


























































/* eslint no-undef: "off"*/
// reference: https://medium.com/dailyjs/google-places-autocomplete-in-vue-js-350aa934b18d
import { Component, Prop, Vue } from 'vue-property-decorator'
import { EnvironmentModule } from '@/_store/modules'
import { IPost } from '@/_store/models'

@Component
export default class Places extends Vue {
  @Prop()
  post!: IPost

  @Prop()
  submitted!: boolean

  googleApiKey: string | undefined = EnvironmentModule.googleApiKey
  // @ts-ignore
  autocomplete: google.maps.places.Autocomplete
  // @ts-ignore
  map: google.maps.Map
  // @ts-ignore
  marker: google.maps.Marker
  searchResults: any[] = []
  service: string | null = null

  setAddress(address: any[], geo: any) {
    let streetNumber = ''
    let streetName = ''
    this.post.geo_lat = geo.lat()
    this.post.geo_lng = geo.lng()
    // @ts-ignore
    for (const component of address as google.maps.GeocoderAddressComponent[]) {
      switch (component.types[0]) {
        case 'street_number':
          streetNumber = component.short_name
          break
        case 'route':
          streetName = component.short_name
          break
        case 'locality':
          this.post.city = component.short_name
          break
        case 'administrative_area_level_1':
          this.post.state = component.short_name
          break
        case 'country':
          this.post.country = component.long_name
          break
        case 'postal_code':
          this.post.zipcode = component.short_name
          break
      }
    }
    this.post.address1 = `${streetNumber} ${streetName}`
    this.postitionMap(this.post.geo_lat, this.post.geo_lng)
    //this.setAddressData()
  }

  postitionMap(lat = 38.897663, lng = -77.03657390000001) {
    let labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let labelIndex = 0
    let coordinates = { lat: lat, lng: lng }
    let mapProp = {
      // @ts-ignore
      center: new google.maps.LatLng(coordinates.lat, coordinates.lng),
      zoom: 16,
    }
    // @ts-ignore
    this.map = new google.maps.Map(document.getElementById('map'), mapProp)
    let markerOptions = {
      position: coordinates,
      map: this.map,
      label: labels[labelIndex++ % labels.length],
    }
    // @ts-ignore
    this.marker = new google.maps.Marker(markerOptions)
  }

  customCoordinates() {
    this.postitionMap(this.post.geo_lat, this.post.geo_lng)
  }

  mounted() {
    setTimeout(() => {
      // @ts-ignore
      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        { types: ['geocode'] }
      )
      this.autocomplete.setFields(['address_component', 'geometry'])
      this.autocomplete.addListener('place_changed', () => {
        let place = this.autocomplete.getPlace()
        this.setAddress(place.address_components, place.geometry.location)
      })

      this.postitionMap()
    }, 1000)
  }
}
