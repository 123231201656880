/* eslint-disable no-unused-vars */
export enum MediaTypes {
  MEDIA_TYPE_IMAGE = 0,
  MEDIA_TYPE_VIDEO = 1,
  MEDIA_TYPE_AUDIO = 2,
  MEDIA_TYPE_MUSIC = 3,
  MEDIA_TYPE_YOUTUBE = 4,
  MEDIA_TYPE_TEXT = 5,
  MEDIA_TYPE_FILE = 6,
  MEDIA_TYPE_LINK = 7,
  MEDIA_TYPE_MAP = 8,
  MEDIA_TYPE_DROPBOX = 9,
  MEDIA_TYPE_UNKNOWN = 10,
  MEDIA_TYPE_SKETCHFAB = 11,
}
