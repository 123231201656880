<template>
  <div class="container">
    <section>
      <h3>Mixonium Group Holdings, Inc. Privacy Policy</h3>

      <p>Effective Date: July 29, 2021</p>

      <p>
        Mixonium Group Holdings, Inc. ("MIXONIUM") has created this privacy
        policy (“Privacy Policy”) to explain and summarize how we collect,
        retain, use and protect your personal information when you visit or use
        MIXONIUM website, mobile applications, and any related features,
        content, products and services (collectively, the “Services”). MIXONIUM
        reserves the right to change this Privacy Policy at any time by posting
        a new privacy policy on our website. By accessing the Services, you
        indicate your agreement to and acceptance of the terms of this Privacy
        Policy, and consent to the collection, retention and use of your
        personally identifiable information consistent with this Privacy Policy,
        as amended from time to time. Your use of the Services is governed at
        all times by the MIXONIUM
        <router-link to="/terms">Terms of Service</router-link>. If you are
        visiting from outside the United States there may be special rules or
        limitations on your use of the Services.
      </p>

      <p><span class="roman-numeral">I.</span>INFORMATION WE COLLECT</p>

      <p>
        MIXONIUM collects and uses the information you provide to us or that we
        learn from your use of the Services as described in this Privacy Policy.
        Whenever we may need to share your personal information, we try to
        provide you with the information you need to make informed choices.
      </p>

      <p>
        MIXONIUM collects and uses your personal information in several ways:
      </p>

      <p>
        <span class="underline">User Accounts</span>. Access to certain of the
        Services will require you to create a user account and/or provide
        certain personal information such as your name and your email address.
        With your consent, we may also use the information you provide in online
        registration forms to notify you periodically about important changes to
        the Services (such as a change to this Privacy Policy or notice of a
        security breach), new MIXONIUM products and services, and special offers
        and contests that may be of interest to you. Regardless of any other
        provision of this Privacy Policy, we may permanently store any personal
        information provided by you via such registration process.
      </p>

      <p>
        <span class="underline">Direct Marketing</span>. MIXONIUM does not share
        your personal information with third parties for their direct marketing
        purposes.
      </p>

      <p>
        <span class="underline">Location Information</span>. MIXONIUM provides
        certain analytics, which you can use to do things such as determine
        where visitors are from, and we use your location information (e.g., by
        using the GPS on your mobile device) to provide functionality for our
        Services. Please keep in mind that other users can see this information
        about you and may use it or disclose it to other individuals or entities
        outside of our control and without your knowledge. Your information may
        be subject to abuse, misuse and monitoring by others and you share this
        information at your own risk.
      </p>

      <p>
        <span class="underline">Device Identifier</span>. MIXONIUM may use your
        device identifier to show you which applications are authorized to
        access your MIXONIUM account and date of last access, provide account
        support, to calculate anonymous aggregate statistics on the number of
        unique devices using the Services, provide anonymous analytics to other
        users of the Services.
      </p>

      <p>
        <span class="underline">Services Usage and Optional Information</span>.
        MIXONIUM, or MIXONIUM authorized service providers, may observe your
        activities, preferences, and transactional data (such as your IP
        address, location information, type of device and software being used,
        use of product features and use of particular types of content) relating
        to your use of the Services. Where you provide optional information,
        such as information about your interests and hobbies, MIXONIUM generally
        uses this information on an aggregate basis to enable us to better
        understand our users, develop new products and services, and improve our
        existing products and services. While we may collect or log this
        information, we generally do not identify you or match this information
        with your other personal information. We may use this collected or
        logged information to diagnose website technical problems, to facilitate
        your use of the Services, improve features and usability of the
        Services, suggest other products and services that may enhance your use
        of the Services or to track aggregate usage within or across the
        Services. Where practical, MIXONIUM limits the access of our service
        providers and vendors who assist with technical support and customer
        service through the use of confidentiality provisions and agreements
        that are intended to enforce this Privacy Policy.
      </p>

      <p>
        <span class="underline">Social Network Profiles</span>. You may also
        choose to connect our Services to your other social network profiles,
        such as Facebook and Twitter. If you choose to connect any additional
        profiles, we may at times use your check-in data and your post
        information to identify your location.
      </p>

      <p>
        <span class="underline">Source Website</span>. We record the source
        website from which you save content to record the source of your
        content. This source-recording is not affected by your browser’s
        do-not-track settings.
      </p>

      <p>
        <span class="underline">Online Transactions</span>. If we offer you the
        opportunity to purchase products or services online and you choose to
        purchase such products or services, you may be asked to share personal
        information in addition to the information provided to create a user
        account in order to complete a transaction, such as your name, telephone
        number, address, gender, credit card information, and other personal
        information. If you are a business user, we may also collect the
        business name and your business email address, street address and
        billing information. You may be asked for information needed to contact
        you to confirm the order, and provide related customer service. This
        information is used, for example, to effect your transactions, to create
        and share aggregate reports about these transactions, and to perform
        credit fraud screening services for transactions between you and
        MIXONIUM and/or our authorized service providers.
      </p>

      <p>
        <span class="underline">Public and Shared Areas</span>. Any information
        and other content (such as written works, pictures or videos) disclosed
        in public areas of the Services will become public information. We
        cannot control the use of information and other content disclosed in
        public forums, such as other social networking sites and user forums,
        nor can we control information and other content disclosed through
        shared sites or pages. You should exercise caution when disclosing
        information and other content in public or shared areas and should
        refrain from disclosing your personal information. Please remember that
        any information or other content that is disclosed in these areas,
        including your name, becomes available to others and you should exercise
        caution when deciding to disclose your personal information.
      </p>

      <p>
        MIXONIUM WILL TREAT ANY PUBLICLY AVAILABLE POSTINGS, FEEDBACK, EMAILS OR
        SUGGESTIONS YOU PROVIDE TO US AS NON-CONFIDENTIAL AND NON-PROPRIETARY.
        IF YOU CHOOSE TO USE YOUR REAL NAME, YOUR NAME WILL BE AVAILABLE TO AND
        VIEWABLE BY ANYONE USING THE WORLD WIDE WEB AND/OR USING A SEARCH
        ENGINE.
      </p>

      <p><span class="roman-numeral">II.</span>USE OF COLLECTED INFORMATION</p>

      <p>
        <span class="underline">Certain Third Party Transactions</span>.
        MIXONIUM may work with some authorized service providers to provide
        certain services, such as technical and customer support. Where
        practical, MIXONIUM will require these service providers to safeguard
        personal information you provide to us. To the extent it is necessary
        for these service providers to complete their contractual obligations to
        MIXONIUM, these third parties may have access to or process your
        personal information. MIXONIUM generally limits their use of your
        personal information to the purpose for which it was shared. MIXONIUM
        may sometimes permit our authorized service providers to have access to
        aggregate statistics about our customers, sales, traffic patterns, and
        related site information.
      </p>

      <p>
        <span class="underline">Mergers, Acquisitions and Bankruptcy</span>. If
        MIXONIUM should ever file for bankruptcy or merge with another company,
        or if MIXONIUM should decide to buy, sell or reorganize some part or all
        of its business, MIXONIUM may be required to disclose your personal
        information to prospective or actual purchasers or receive personal
        information from sellers. It is MIXONIUM’s practice to seek appropriate
        protection for information disclosed or received in these types of
        transactions.
      </p>

      <p>
        <span class="underline"
          >As Required by Law and Other Extraordinary Disclosures</span
        >. MIXONIUM may be required to disclose your personal information if it:
        (a) believes it is reasonably necessary to comply with legal process
        (such as a court order, subpoena, search warrant, etc.) or other legal
        requirements of any governmental authority; (b) would potentially
        mitigate our liability in an actual or potential lawsuit; (c) is
        otherwise necessary to protect our rights or property; or (d) is
        necessary protect the interest of other users of our Services.
      </p>

      <p><span class="roman-numeral">III.</span>CHILDREN</p>

      <p>
        MIXONIUM does not knowingly collect or solicit personal information from
        anyone under the age of 13 or knowingly allow such persons to register.
        If you are under 13, you should not attempt to register for the Services
        or send information about yourself to us, including your name, address,
        telephone number, or email address. No one under age 13 may provide any
        personal information to or through our Services. If a child under 13
        submits information through any part of the Services without
        verification of parental consent, and we learn the person submitting the
        information is a child or such consent has not been verified, we will
        attempt to delete this information as soon as possible. Because we do
        not knowingly collect any personal information from children under 13,
        we do not use or disclose such information to third parties. If you
        believe that MIXONIUM may have any information about a child under 13,
        please contact us at contact@MIXONIUM.com.
      </p>

      <p><span class="roman-numeral">IV.</span>SECURITY</p>

      <p>
        No data transmissions over the Internet can be guaranteed to be 100%
        secure. Consequently, we cannot ensure or warrant the security of any
        information you transmit to us and you do so at your own risk. Once we
        receive your transmission, we make reasonable efforts to ensure security
        on our systems. We use secure server software (SSL) and firewalls to
        protect your information from unauthorized access, disclosure,
        alteration, or destruction. However, please note that this is not a
        guarantee that such information may not be accessed, disclosed, altered
        or destroyed by breach of such firewalls and secure server software.
        MIXONIUM is not responsible for circumvention of any privacy settings or
        security measures contained among the Services.
      </p>

      <p>
        You understand and acknowledge that, even after removal, copies of your
        information may remain viewable in cached or archived pages or if other
        users who you permitted to access your information have copied or stored
        your information.
      </p>

      <p>
        If MIXONIUM learns of a security systems breach we may attempt to notify
        you electronically so that you can take appropriate protective steps. By
        using the Services or providing personal information to us, you agree
        that we can communicate with you electronically regarding security,
        privacy and administrative issues relating to your use of the Services.
        MIXONIUM may post a notice on our website if a security breach occurs.
        If this happens, you will need a web browser enabling you to view the
        website. MIXONIUM may also send an email to you at the email address you
        have provided to us in these circumstances. Depending on where you live,
        you may have a legal right to receive notice of a security breach in
        writing. To receive free written notice of a security breach (or to
        withdraw your consent from receiving electronic notice) you should
        notify us at contact@MIXONIUM.com.
      </p>

      <p>
        <span class="roman-numeral">V.</span>INTERNATIONAL VISITORS AND
        CUSTOMERS
      </p>

      <p>
        Parts of this Privacy Policy may not apply to visitors from outside the
        United States because certain services are not offered to those outside
        the United States.
      </p>

      <p>
        Because MIXONIUM operates in many countries, circumstances could arise
        where it is necessary for us to make your personal information available
        to our worldwide business units and affiliates. Privacy laws differ
        across the globe. If we share your information with a MIXONIUM affiliate
        or business unit that is outside of the United States, we will do so
        consistent with this Privacy Policy.
      </p>

      <p>
        The Services are hosted in the United States and is intended for United
        States visitors. If you are visiting from the European Union or other
        regions with laws governing data collection and use that may differ from
        United States law, please note that you are transferring your personal
        data, including any content you create using the Services, to the United
        States and by providing your personal data you consent to that transfer.
        When you provide personal data to MIXONIUM or our service providers or
        vendors, you consent to the processing of your data in the United States
        as the Services are operated in the United States.
      </p>

      <p>
        <span class="roman-numeral">VI.</span>THIRD-PARTY SITES AND
        ADVERTISEMENTS
      </p>

      <p>
        The Services may contain links to third-party sites, including
        co-branded sites (such as web sites that may display MIXONIUM’s logo or
        trademarks but are maintained by third parties), however, please be
        aware that MIXONIUM is not responsible for and cannot control the
        privacy practices of these other sites. We encourage you to read the
        privacy policies for these other sites, as they may differ from ours.
        This Privacy Policy applies solely to personal information collected by
        MIXONIUM.
      </p>

      <p>
        Advertisements that appear on the Services are sometimes delivered (or
        "served") directly to users by third party advertisers. Such third
        parties automatically receive your IP address when this happens. These
        third party advertisers may also download cookies to your computer, or
        use other technologies such as JavaScript and "web beacons" (also known
        as "1x1 gifs") to measure the effectiveness of their ads and to
        personalize advertising content. Doing this allows the advertising
        network to recognize your computer each time they send you an
        advertisement in order to measure the effectiveness of their ads and to
        personalize advertising content. In this way, they may compile
        information about where individuals using your computer or browser saw
        their advertisements and determine which advertisements are clicked.
        MIXONIUM does not have access to or control of the cookies that may be
        placed by the third party advertisers. Third party advertisers have no
        access to your personal information stored when you use the Services
        unless you choose to share it with them.
      </p>

      <p>
        This Privacy Policy covers the use of cookies by MIXONIUM and does not
        cover the use of cookies or other tracking technologies by any of its
        advertisers.
      </p>

      <p><span class="roman-numeral">VII.</span>CONTACT PREFERENCES</p>

      <p>
        When you provide MIXONIUM with personal information, if you allow us, we
        may communicate with you by the method you choose about topics of your
        choosing. MIXONIUM seeks your consent to receive marketing
        communications from us when you provide your personal information.
      </p>

      <p>
        Generally, when we contact you, we will try to provide you with an easy
        and convenient way to notify us if you would not like further similar
        communications. To request that we remove your personally identifiable
        information from our database or to “opt-out” of any further e-mail or
        contact, send a request to contact@MIXONIUM.com. MIXONIUM will make a
        good faith effort to comply with such written request within thirty days
        of receipt of the request.
      </p>

      <p><span class="roman-numeral">VIII.</span>CONTACTING MIXONIUM, INC.</p>

      <p>
        If you have any questions, comments, or concerns about this Privacy
        Policy, or would like to make changes to your personal information or
        contact preferences, or delete personal information you have provided to
        us, please email us at contact@MIXONIUM.com.
      </p>

      <p>
        Postal Mail Address:<br />
        Mixonium Group Holdings, Inc.<br />
        4829 Corrales Rd<br />
        Corrales, NM 87048
      </p>

      <p>
        If you have a privacy concern regarding MIXONIUM or this Privacy Policy
        and if you cannot satisfactorily resolve it through the use of the
        Services you should then write to: Legal Department at the address noted
        above. In the event that we are still unable to resolve your concerns,
        MIXONIUM is committed to cooperating to achieve a proper resolution of
        your privacy concerns.
      </p>
    </section>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class PrivacyPolicy extends Vue {}
</script>

<style scoped></style>
