const CellPositions: Array<number> = [1, 2, 3, 4, 5, 6, 7]

const GeneralFileTypes: string[] = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
  'video/mp4',
  'video/m4v',
  'video/avi',
  'video/x-matroska',
  'video/quicktime',
  'video/wmv',
  'audio/mp3',
  'audio/wav',
  'audio/wma',
  'audio/ogg',
  'audio/aac',
  'audio/aiff',
  'audio/mpeg',
]

const CellEditFileTypes: string[] = ['image/png', 'image/jpg', 'image/jpeg']

export { CellPositions, GeneralFileTypes, CellEditFileTypes }
