import { render, staticRenderFns } from "./ClonedPostModal.vue?vue&type=template&id=416bde3e&scoped=true&"
import script from "./ClonedPostModal.vue?vue&type=script&lang=ts&"
export * from "./ClonedPostModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "416bde3e",
  null
  
)

export default component.exports