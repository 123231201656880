
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Meta } from '@sophosoft/vue-meta-decorator'

@Component({})
export default class PreviewWidget extends Vue {
  @Meta
  getMetaInfo() {
    return {
      title: 'Preview Widget',
    }
  }

  @Prop()
  embededIdProp!: string

  embededId = this.embededIdProp || this.$route.params.id
}
