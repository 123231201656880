import Vue from 'vue'
import { Api, KeenApi } from './_services'
import { EnvironmentModule } from './_store/modules'
const api = new Api()
const keenApi = new KeenApi()

declare module 'vue/types/vue' {
  /* eslint-disable-next-line*/
  interface Vue {
    $scrollToTop: Function
    $useMailChimp: boolean
    $api: Api
    $keenApi: KeenApi
    $isMobile: Function
    $numeral: Function
  }
}
const scrollToTop = () => {
  window.scrollTo({
    top: 100,
    left: 100,
    behavior: 'smooth',
  })
}

const isMobile = () => {
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

const useMailChimp = EnvironmentModule.useMailChimp === 'true'

const prototypesInit = () => {
  Vue.prototype.$scrollToTop = scrollToTop
  Vue.prototype.$useMailChimp = useMailChimp
  Vue.prototype.$api = api
  Vue.prototype.$keenApi = keenApi
  Vue.prototype.$isMobile = isMobile
}

export default prototypesInit
