import { AdminAccounts, AdminAccountView } from '@/views/admin'
const AdminAccountRoutes = [
  {
    path: '/admin/account/:id',
    name: 'admin-account-view',
    component: AdminAccountView,
    meta: { requiresAdminAuth: true, hideFooter: true },
  },
  {
    path: '/admin/accounts',
    name: 'admin-accounts',
    component: AdminAccounts,
    meta: { requiresAdminAuth: true, hideFooter: true },
  },
]
export default AdminAccountRoutes
