










import { Vue, Component, Watch } from 'vue-property-decorator'
import { NavBar, UiFooter, Alerts } from '@/components/ui'
import { ModalLogin } from '@/components/modals'
import { setHeaderScripts } from '@/_utilities'
import { IAlert } from './types'
import { mapActions, mapGetters } from 'vuex'

@Component({
  metaInfo() {
    return {
      title: 'MIXONIUM™',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    }
  },
  components: {
    UiFooter,
    NavBar,
    ModalLogin,
    Alerts,
  },
  methods: {
    ...mapGetters(['StateUser', 'StateExpiry']),
    ...mapActions(['SetNewExpiry', 'LogOut']),
  },
})
export default class App extends Vue {
  StateUser!: Function
  StateExpiry!: Function
  LogOut!: Function
  SetNewExpiry!: Function

  alert: IAlert = {
    message: null,
    alertType: null,
  }

  @Watch('$route')
  routeChanged() {
    this.checkAuthExpiry()
  }

  get routeHasFooter() {
    return !this.$route.meta.hideFooter
  }

  get loading() {
    return this.$store.getters.StateLoading
  }

  get user() {
    return this.StateUser()
  }

  get expiry() {
    return this.StateExpiry()
  }

  sendAlertMessage(alert: IAlert) {
    this.alert = alert
  }

  resetAlertProps() {
    this.alert.alertType = null
    this.alert.message = null
  }

  alertSessionExpired() {
    const alert: IAlert = {
      message: 'You session has expired, please login again.',
      alertType: 'warning',
      timeout: 5000,
    }
    this.sendAlertMessage(alert)
  }

  async checkAuthExpiry() {
    if (this.user) {
      if (this.expiry === null) {
        await this.LogOut()
        this.alertSessionExpired()
        return
      }
      if (new Date() > new Date(this.expiry)) {
        await this.LogOut()
        this.alertSessionExpired()
        return
      } else {
        await this.SetNewExpiry()
      }
    }
  }

  mounted() {
    setHeaderScripts()
    this.checkAuthExpiry()
  }
}
