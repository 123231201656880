

















































































































































import { Component, Emit, Vue } from 'vue-property-decorator'
import { IAlert, KeenTimeFrame } from '@/types'
import router from '@/router'
import { KeenFilter } from '@/interfaces'

@Component({})
export default class AnalyticsList extends Vue {
  posts: any[] = []
  postIds: any[] = []
  cellViews: any[] = []
  postViews: any[] = []
  isLoading = true
  cellViewsCount: any[] = []
  postViewsCount: any[] = []
  filter: KeenTimeFrame = 'this_10_years'

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  get postViewsMax() {
    return Math.max(...this.postViewsCount)
  }

  get cellViewsMax() {
    return Math.max(...this.cellViewsCount)
  }

  get isMobile() {
    const yes = this.$isMobile()
    return yes
  }

  widthPercentage(max: number, current: number) {
    return (current / max) * 100
  }

  getTitle(id: number) {
    const post = this.posts.find(obj => {
      return obj.id === id
    })

    return post
      ? post.title === ''
        ? '< No Title Set >'
        : post.title
      : '< No Title Set >'
  }

  getCellsViewed(id: number) {
    const views = this.cellViews.find(obj => {
      return obj.post_id === id
    })

    return views ? views.result : 0
  }

  getPostLoaded(id: number) {
    const views = this.postViews.find(obj => {
      return obj.id === id
    })

    return views ? views.result : 0
  }

  sortPosts(val: boolean) {
    if (val) {
      const list = [...this.posts]
      this.posts = list.sort((a: any, b: any) =>
        a.created_at > b.created_at ? 1 : -1
      )
    } else {
      const popular = [...this.postViews]
      const popOrder = popular.sort((a: any, b: any) =>
        a.result > b.result ? 1 : -1
      )
      const list = [...this.posts]
      let result: any[] = []

      popOrder.forEach((key: any) => {
        var found = false
        list.filter((post: any) => {
          if (!found && post.id === key.id) {
            result.push(post)
            found = true
            return false
          } else {
            return true
          }
        })
      })
      this.posts = result.reverse()
      list.forEach((post: any) => {
        if (!this.posts.includes(post)) {
          this.posts.push(post)
        }
      })
    }
  }

  async setFilter() {
    this.isLoading = true
    await this.getPostEvents()
    await this.getCellEvents()
    this.sortPosts(false)
    this.isLoading = false
  }

  async getPostList() {
    const response = await this.$api.getMyPostsNoPage()
    if (response.status === 200) {
      if (response.data.length > 0) {
        this.posts = response.data
        this.postIds = response.data.map((post: any) => post.id)
        await this.setFilter()
      }
    } else {
      alert('There was a system error. Please contact support!')
    }
  }

  async getPostEvents() {
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'in',
        property_value: this.postIds,
      },
    ]
    await this.$keenApi
      .analysis('count', 'Post Loaded', this.filter, 'id', 'id', filter)
      .then((resp: any) => {
        this.postViews = resp
        this.postViewsCount = resp.map((obj: any) => obj.result)
      })
      .catch((e: any) => {
        const alert: IAlert = {
          message:
            'There was a system error when loading Analytics. Please contact support!',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
        this.isLoading = false
        router.push('/posts/my-posts')
        throw e
      })
  }

  async getCellEvents() {
    const filter: KeenFilter[] = [
      {
        property_name: 'post_id',
        operator: 'in',
        property_value: this.postIds,
      },
    ]
    await this.$keenApi
      .analysis(
        'count',
        'Cell Viewed',
        this.filter,
        'post_id',
        'cell_number',
        filter
      )
      .then((resp: any) => {
        this.cellViews = resp
        this.cellViewsCount = resp.map((obj: any) => obj.result)
      })
      .catch((e: any) => {
        const alert: IAlert = {
          message:
            'There was a system error when loading Analytics. Please contact support!',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
        this.isLoading = false
        router.push('/posts/my-posts')
        throw e
      })
  }

  async mounted() {
    await this.getPostList()
  }
}
