
















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ClonedPostModal extends Vue {
  @Prop()
  readonly postId!: string | number

  @Prop()
  readonly title!: string

  @Prop()
  readonly img!: string

  @Prop()
  resetModal!: Function
}
