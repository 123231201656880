





























































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { IClub, IClubImgCropUpdate, IUser } from '@/_store/models'
//@ts-ignore
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { mapActions, mapGetters } from 'vuex'

@Component({
  components: {
    Cropper,
  },
  methods: {
    ...mapActions(['ReplaceClubImg']),
    ...mapGetters(['StateUser']),
  },
})
export default class ClubImgModal extends Vue {
  StateUser!: Function
  ReplaceClubImg!: Function

  @Prop()
  readonly club!: IClub

  @Prop()
  readonly showCropper!: boolean

  @Prop()
  hideCropper!: Function

  croppingImage: boolean = false
  changingClubImage: boolean = false
  imageChangedEvent: string = ''
  cropperAspectRatio: string = '4/3'
  savingImg = false

  get user() {
    const user: IUser = this.StateUser()
    return user
  }

  get imgSrc() {
    return this.club.thumbnail
  }

  async fileChangeEvent(event: any) {
    if (event === null || event === undefined) {
      return
    }
    this.changingClubImage = true
    this.imageChangedEvent = ''
    const replacement: IClubImgCropUpdate = {
      clubId: this.club.group.id,
      new_image: true,
      imageblob: event.target.files[0],
    }
    await this.ReplaceClubImg(replacement)
    document
      .getElementById('cropperImageThumb')
      ?.setAttribute('src', this.club?.thumbnail || '')

    this.changingClubImage = false
    document.querySelector('.modal-backdrop')?.remove()
    this.hideCropper()
  }

  setAspectRatio() {
    return 4 / 3
  }

  async saveNewImage() {
    // @ts-ignore
    const { canvas } = this.$refs.imgCropper.getResult()
    const image = canvas.toDataURL()
    this.croppingImage = true
    const replacement: IClubImgCropUpdate = {
      clubId: this.club.group.id,
      new_image: true,
      imageblob: image,
    }
    await this.ReplaceClubImg(replacement)
    document
      .getElementById('cropperImageThumb')
      ?.setAttribute('src', this.club?.thumbnail || '')

    this.croppingImage = false
    document.querySelector('.modal-backdrop')?.remove()
    this.hideCropper()
  }
}
