import EditWidget from './EditWidget.vue'
import NavBar from './NavBar.vue'
import UiFooter from './UiFooter.vue'
import Alerts from './Alerts.vue'
import Loader from './Loader.vue'
import Places from './Places.vue'
import RibbonOverlay from './RibbonOverlay.vue'
import WidgetViewer from './WidgetViewer.vue'
import PostFieldsView from './PostFieldsView.vue'
import LockedResource from './LockedResource.vue'
import AnalyticsMap from './AnalyticsMap.vue'

export {
  NavBar,
  UiFooter,
  Alerts,
  Loader,
  Places,
  RibbonOverlay,
  EditWidget,
  WidgetViewer,
  PostFieldsView,
  LockedResource,
  AnalyticsMap,
}
