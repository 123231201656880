
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  methods: {
    ...mapGetters(['StateLoading']),
  },
})
export default class Loader extends Vue {
  @Prop({ default: null })
  readonly isLoading!: boolean

  StateLoading!: Function

  get loading() {
    return this.isLoading !== null ? this.isLoading : this.StateLoading()
  }
}
