


















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapActions } from 'vuex'
import CellOverlay from './cells/CellOverlay.vue'
import CellEditModal from '../modals/CellEditModal.vue'
import { IPost } from '@/_store/models'
import { IMediaItems } from '@/_store/models'
import { MediaTypes, CellPositions, GeneralFileTypes } from '@/constants'
import {
  IPostMediaItem,
  IPostMediaItemSketchFab,
  IPostMediaItemWebUrl,
  IPostMediaItemYoutube,
} from '@/interfaces'
import { IAlert } from '@/types'

@Component({
  components: {
    CellEditModal,
    CellOverlay,
  },
  methods: {
    ...mapActions(['GetPostForEdit']),
  },
})
export default class EditWidget extends Vue {
  @Prop()
  post!: IPost

  @Prop()
  sendAlertMessageUp!: Function

  GetPostForEdit!: Function

  cell_positions = CellPositions
  acceptableFiletypes = GeneralFileTypes
  cellSize = (position: number) => {
    const x = position
    switch (true) {
      case x === 1:
        return ''
      case x === 2 || x === 3:
        return 'cell-medium'
      case x > 3:
        return 'cell-small'
    }
  }
  selectedMediaItem: IMediaItems | null = null
  current_hover!: number
  //upload_percentage: number | undefined
  hoveredCell = 0
  currentCellLoading = 0
  cellsLoading: any[] = []
  pollingCells: any[] = []

  setHoveredCell(p: number) {
    this.hoveredCell = p
  }

  getMediaItem(position: number) {
    if (typeof this.post.media_items === 'undefined') {
      return null
    }
    const item = this.post.media_items.find(
      (mi: IMediaItems) => mi.position === position
    )

    if (typeof item === 'undefined') {
      return null
    } else {
      return item
    }
  }

  getImageUrl(position: number) {
    var mi
    if (this.post.media_items?.some((mi: any) => mi.position === position)) {
      mi = this.post.media_items.find((mi: any) => mi.position === position)
    }
    if (typeof mi === 'undefined') {
      return ''
    }
    if (mi.status === 'progressing') {
      this.pollMediaStatus(position)
      let imgSrc
      switch (position) {
        case 1:
          imgSrc = require('@/assets/img/status/large-processing.gif')
          break
        case 2:
        case 3:
          imgSrc = require('@/assets/img/status/tall-processing.gif')
          break
        default:
          imgSrc = require('@/assets/img/status/small-processing.gif')
      }
      return imgSrc
    } else {
      if (position === 1) {
        return mi.image_big_cell_url
      } else if (position < 4) {
        return mi.image_tall_cell_url
      } else {
        return mi.image_small_cell_url
      }
    }
  }

  async pollMediaStatus(position: number) {
    if (!this.pollingCells.includes(position)) {
      this.pollingCells.push(position)
    } else {
      return
    }
    let keepTrying = true
    let attempts = 15
    while (attempts > 0 && keepTrying && this.pollingCells.includes(position)) {
      // see destroyed() method
      attempts -= 1
      const response = await this.$api.pollMediaItem(this.post.id, position)
      if (response.status === 200) {
        const data: IMediaItems = { ...(await response.data) }
        if (data.status == 'complete') {
          keepTrying = false
          this.replaceCellMediaItem(data)
          break
        }
      } else if (response.status === 404) {
        console.log(`media item for position ${position} was not found!`)
        this.removeCellLoading(position)
        break
      }
      // force the loop to pause 10 seconds
      /* eslint-disable no-await-in-loop */
      await new Promise(resolve => setTimeout(resolve, 10000))
    }
    if (!keepTrying) {
      const pIndex = this.pollingCells.indexOf(position)
      if (pIndex > -1) {
        this.pollingCells.splice(pIndex, 1)
      }
    }
  }

  setCellUploading(position: number) {
    let imgSrc
    switch (position) {
      case 1:
        imgSrc = require('@/assets/img/status/upload_cell_big.gif')
        break
      case 2:
      case 3:
        imgSrc = require('@/assets/img/status/upload_cell_tall.gif')
        break
      default:
        imgSrc = require('@/assets/img/status/upload_cell_small.gif')
        break
    }
    const cellParent = document.getElementById(`cell-${position}-img-parent`)
    const imageCell = document.getElementById(`cell-${position}-img`)
    if (imageCell) {
      imageCell.style.display = 'none'
    }
    const loadingImg = document.createElement('img')
    loadingImg.setAttribute('src', imgSrc)
    loadingImg.setAttribute('id', `cell-${position}-img-loading`)
    if (cellParent) {
      cellParent.setAttribute('disable-overlay', 'true')
      cellParent.prepend(loadingImg)
    }
    this.currentCellLoading = position
  }

  setCellLoading(position: number) {
    if (!this.cellsLoading.includes(position)) {
      this.cellsLoading.push(position)
    }
    let imgSrc
    switch (position) {
      case 1:
        imgSrc = require('@/assets/img/status/large-processing.gif')
        break
      case 2:
      case 3:
        imgSrc = require('@/assets/img/status/tall-processing.gif')
        break
      default:
        imgSrc = require('@/assets/img/status/small-processing.gif')
        break
    }
    const cellParent = document.getElementById(`cell-${position}-img-parent`)
    const imageCell = document.getElementById(`cell-${position}-img`)
    if (imageCell) {
      imageCell.style.display = 'none'
    }
    const existingLoading = document.getElementById(
      `cell-${position}-img-loading`
    )
    if (existingLoading) {
      existingLoading.remove()
    }
    const loadingImg = document.createElement('img')
    loadingImg.setAttribute('src', imgSrc)
    loadingImg.setAttribute('id', `cell-${position}-img-loading`)
    if (cellParent) {
      cellParent.setAttribute('disable-overlay', 'true')
      cellParent.prepend(loadingImg)
    }
    this.currentCellLoading = position
  }

  removeCellLoading(position: number) {
    const pIndex = this.cellsLoading.indexOf(position)
    if (pIndex > -1) {
      this.cellsLoading.splice(pIndex, 1)
    }

    const cellParent = document.getElementById(`cell-${position}-img-parent`)
    const loadingImg = document.getElementById(`cell-${position}-img-loading`)
    if (loadingImg) {
      loadingImg.remove()
    }
    const imageCell = document.getElementById(`cell-${position}-img`)
    if (imageCell) {
      imageCell.removeAttribute('style')
    }

    if (cellParent) {
      cellParent.removeAttribute('disable-overlay')
    }
  }

  cleanZone() {
    this.hoveredCell = 0
  }

  setSelectedMediaItem(p: number) {
    this.selectedMediaItem = this.getMediaItem(p)
  }

  youtubeIdFromUrl(url: string) {
    var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
    var match = url.match(regExp)
    if (match && match[1].length === 11) {
      return match[1]
    } else {
      return false
    }
  }

  youtubeImageUrl(url: string) {
    // http://img.youtube.com/vi/Uk_vV-JRZ6E/0.jpg
    return 'http://img.youtube.com/vi/' + this.youtubeIdFromUrl(url) + '/0.jpg'
  }

  async handleUrlDrop(url: string, position: number) {
    const current_media_item = this.getMediaItem(position)
    if (current_media_item) {
      current_media_item.status = 'progressing'
    }
    this.current_hover = -1

    if (url.substring(0, 4) !== 'http') {
      url = 'http://' + url
    }

    if (
      (url.indexOf('youtube.com') !== -1 || url.indexOf('youtu.be') !== -1) &&
      this.youtubeIdFromUrl(url) !== false
    ) {
      const data: IPostMediaItemYoutube = {
        post_id: this.post.id,
        position: position,
        media_type: MediaTypes.MEDIA_TYPE_YOUTUBE,
        youtube_image_url: this.youtubeImageUrl(url),
        youtube_url:
          'http://gdata.youtube.com/feeds/api/videos/' +
          this.youtubeIdFromUrl(url),
      }
      this.setCellLoading(position)
      this.selectedMediaItem = null
      this.cleanZone()
      const response = await this.$api.replaceMediaItemImageWithYoutubeUrl(data)
      if (response.status == 202) {
        this.replaceCellMediaItem(response.data)
        this.current_hover = -1
        this.pollMediaStatus(position)
      } else {
        const alert: IAlert = {
          message: 'Something went from creating your item! Contact support.',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
      }
    } else if (
      url.indexOf('sketchfab.com') !== -1 ||
      url.indexOf('skfb.ly') !== -1
    ) {
      const data: IPostMediaItemSketchFab = {
        post_id: this.post.id,
        position: position,
        media_type: MediaTypes.MEDIA_TYPE_SKETCHFAB,
        sketchfab_url: url,
      }
      this.setCellLoading(position)
      this.selectedMediaItem = null
      this.cleanZone()
      const response = await this.$api.replaceMediaItemImageWithSketchFabUrl(
        data
      )
      if (response.status == 202) {
        this.replaceCellMediaItem(response.data)
        this.current_hover = -1
        this.pollMediaStatus(position)
      } else {
        const alert: IAlert = {
          message: 'Something went from creating your item! Contact support.',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
      }
    } else {
      this.setCellLoading(position)
      this.selectedMediaItem = null
      this.cleanZone()
      const data: IPostMediaItemWebUrl = {
        post_id: this.post.id,
        position: position,
        media_type: MediaTypes.MEDIA_TYPE_LINK,
        link_url: url,
      }
      const response = await this.$api.replaceMediaItemImageWithUrl(data)
      if (response.status == 202) {
        this.replaceCellMediaItem(response.data)
        this.current_hover = -1
        this.pollMediaStatus(position)
      } else {
        const alert: IAlert = {
          message: 'Something went from creating your item! Contact support.',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
      }
    }
  }

  estimateTime(file_size: number, file_name: string) {
    // Attempt to estimate time
    // Aws is roughly 155 Mbps up so, time multiplied by aws overhead
    const aws_upload_speed = 0.0333
    let time_estimate = 0
    let transcode_time = 0
    if (file_name.endsWith('.m4v')) {
      transcode_time = file_size / 35232153.6
      time_estimate = transcode_time * aws_upload_speed + transcode_time
    } else {
      transcode_time = file_size / 8343017.7
      time_estimate = transcode_time * aws_upload_speed + transcode_time
    }
    const alert: IAlert = {
      message: '',
      alertType: 'success',
      timeout: 10000,
    }

    if (time_estimate < 2.0) {
      alert.message =
        'You video was successfully uploaded.<br/>Your Post will update once processing is completed.<br/>Your file should take less than 2 mins.'

      this.sendAlertMessageUp(alert)
    } else {
      alert.message = `Your video was successfully uploaded.<br/>Your Post will update once processing is completed.<br/>Your file should take approx ${time_estimate
        .toFixed(0)
        .toString()} mins.`
      this.sendAlertMessageUp(alert)
    }
  }

  isVideo(fileType: string, lowerName: string) {
    const vidTypes: any[] = [
      'video/mp4',
      'video/m4v',
      'video/avi',
      'video/mkv',
      'video/mov',
      'video/wmv',
    ]
    return vidTypes.indexOf(fileType) > -1 || lowerName.endsWith('.mov')
  }

  isAudio(fileType: string) {
    const audioTypes: any[] = [
      'audio/mp3',
      'audio/wav',
      'audio/wma',
      'audio/ogg',
      'audio/aac',
      'audio/aiff',
    ]
    return audioTypes.indexOf(fileType) > -1
  }

  async onFileSelect(files: any, position: number) {
    if (files.dataTransfer && files.dataTransfer.getData('url') !== '') {
      this.handleUrlDrop(await files.dataTransfer.getData('url'), position)
      return
    } else if (files.dataTransfer && files.dataTransfer.files.length > 0) {
      files = await files.dataTransfer.files
    } else if (!files.dataTransfer && files.target?.files.length > 0) {
      files = await files.target.files
    } else {
      return
    }

    for (var e = 0; e < 1; e++) {
      // Make sure the file that was dragged and dropped is supported
      var nfile = files[e]
      var oriName = nfile.name
      var lowerName = nfile.name.toLowerCase()
      if (
        this.acceptableFiletypes.indexOf(nfile.type) > -1 ||
        lowerName.endsWith('.mov')
      ) {
        continue
      } else {
        const alert: IAlert = {
          message: `The file you uploaded "${oriName}" is not an acceptable file type!<br/>Upload ONLY one of the following file types:<br/>Images: png, jpg, jpeg<br/>nDocuments: pdf<br/>Audio and Video: aac, aiff, avi, m4v, mkv, mov, mp3, mp4, ogg, wav, wma, wmv`,
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
        return
      }
    }

    var current_media_item = this.getMediaItem(position)

    //delete this.upload_percentage[position]
    if (current_media_item) {
      current_media_item.status = 'initial'
    }
    this.setCellUploading(position)

    this.current_hover = -1
    for (let i = 0; i < 1; i++) {
      // just one for now, change when we do galleries
      let file = files[i]

      //Only allow files greater than 250MB and less than 15GB if permitted
      if (
        (this.post.account_type === 'null' ||
          this.post.account_type === 'basic') &&
        file.size > 157286400
      ) {
        const alert: IAlert = {
          message:
            'Your account does not allow file uploads greater than 150MB.<br/>Please select a smaller file!',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
        this.removeCellLoading(position)
        this.GetPostForEdit(this.post.id)
        break
      } else if (this.post.account_type === 'pro' && file.size > 314572800) {
        const alert: IAlert = {
          message:
            'Your account does not allow file uploads greater than 300MB.<br/>Please select a smaller file!',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
        this.removeCellLoading(position)
        this.GetPostForEdit(this.post.id)
        break
      } else if (
        this.post.account_type === 'enterprise' &&
        file.size > 2147483648
      ) {
        const alert: IAlert = {
          message:
            'Your account does not allow file uploads greater than 2GB.<br/>Please select a smaller file!',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
        this.removeCellLoading(position)
        this.GetPostForEdit(this.post.id)
        break
      }

      const replacement: IPostMediaItem = {
        postId: this.post.id,
        position: position,
        fileOrData: file,
        mediaType: this.detectMediaType(file.type),
      }
      // if (current_media_item) {
      //   current_media_item.status = 'progressing'
      // }
      const response = await this.$api.replaceMediaItem(replacement)
      if (response.status == 202) {
        const media_item = response.data
        // this.replaceCellMediaItem(media_item)
        this.setCellLoading(position)
        if (
          (media_item &&
            media_item.media_type === MediaTypes.MEDIA_TYPE_VIDEO) ||
          (media_item && media_item.media_type === MediaTypes.MEDIA_TYPE_AUDIO)
        ) {
          if (media_item.media_type === MediaTypes.MEDIA_TYPE_VIDEO) {
            this.estimateTime(file.size, file.name)
          } else {
            const alert: IAlert = {
              message:
                'You audio file was successfully uploaded.\nYour Post will update once processing is completed.',
              alertType: 'success',
            }
            this.sendAlertMessageUp(alert)
          }
        }
        if (media_item) {
          this.pollMediaStatus(position)
        }
      } else {
        const alert: IAlert = {
          message: 'Something went from creating your item! Contact support.',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
      }
    }
  }

  async replaceCellMediaItem(media_item: IMediaItems) {
    if (this.post.media_items && this.post.media_items.length > 0) {
      const mediaItemIndex = this.post.media_items.findIndex(
        (mi: IMediaItems) => {
          return mi.position === media_item.position
        }
      )
      if (mediaItemIndex !== -1) {
        this.post.media_items[mediaItemIndex] = media_item
      }
    }
    this.removeCellLoading(media_item.position)
    this.currentCellLoading = 0
    const imgDiv = document.getElementById(`cell-${media_item.position}-img`)
    if (imgDiv === undefined || imgDiv === null) {
      // If this div doesn't exist, it's a first time cell update
      this.GetPostForEdit(this.post.id)
    } else {
      // Wait 1 second before force update or image won't show!
      /* eslint-disable no-await-in-loop */
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.$forceUpdate()
    }
  }

  detectMediaType(fileType: string) {
    switch (fileType.toLowerCase()) {
      case 'image/png':
      case 'image/jpg':
      case 'image/jpeg':
        return MediaTypes.MEDIA_TYPE_IMAGE
      case 'video/mp4':
      case 'video/m4v':
      case 'video/avi':
      case 'video/mkv':
      case 'video/mov':
      case 'video/wmv':
        return MediaTypes.MEDIA_TYPE_VIDEO
      case 'audio/mp3':
      case 'audio/wav':
      case 'audio/wma':
      case 'audio/ogg':
      case 'audio/aac':
      case 'audio/aiff':
        return MediaTypes.MEDIA_TYPE_AUDIO
      default:
        return MediaTypes.MEDIA_TYPE_UNKNOWN
    }
  }

  async pollProgressingCells() {
    const mediaItems: IMediaItems[] | undefined = await this.post.media_items
    if (mediaItems && mediaItems.length > 0) {
      const itemsToPoll = mediaItems.map((mi: IMediaItems) => mi.position)
      itemsToPoll.forEach((position: number) => {
        this.pollMediaStatus(position)
      })
    }
  }

  mounted() {
    this.pollProgressingCells
  }

  destroyed() {
    // Stop all polling on component destroy
    this.pollingCells = []
  }
}
