import { render, staticRenderFns } from "./DesktopNav.vue?vue&type=template&id=225fc408&scoped=true&"
import script from "./DesktopNav.vue?vue&type=script&lang=ts&"
export * from "./DesktopNav.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225fc408",
  null
  
)

export default component.exports