



































































import { Component, Vue } from 'vue-property-decorator'
import { Meta } from '@sophosoft/vue-meta-decorator'
import { mapActions, mapGetters } from 'vuex'
import { IClubShort, IMemberClubs } from '@/_store/models'

@Component({
  computed: {
    ...mapGetters(['StateMemberClubs', 'StateLoading']),
  },
  methods: {
    ...mapActions(['GetMemberClubs']),
  },
})
export default class MemberClubs extends Vue {
  StateMemberClubs!: IMemberClubs[]
  StateLoading!: boolean
  GetMemberClubs!: Function

  @Meta
  getMetaInfo() {
    return {
      title: 'Member Clubs',
    }
  }

  get loading() {
    return this.StateLoading
  }

  get clubs() {
    return this.StateMemberClubs
  }

  visibility(club: IClubShort) {
    return club.public ? 'public' : 'clubs_only'
  }

  getmemberClubs() {
    return this.GetMemberClubs()
  }

  created() {
    this.getmemberClubs()
  }
}
