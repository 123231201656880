import store from '@/store'
import { IPost, IPostSearch } from '@/_store/models'
import axios from 'axios'

class PostMethods {
  async sendPostEmail(post: IPost) {
    const response = await axios.post(`/api/v1/posts/email`, post, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async createNewPost() {
    const response = await axios.post(
      `/api/v1/posts`,
      {},
      {
        headers: {
          // @ts-ignore
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async getMyPosts(params: IPostSearch) {
    const response = await axios.get('/api/v1/posts?webclient=1', {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
      params: params,
    })
    return response
  }

  async getMyPostsNoPage() {
    const response = await axios.get('/api/v1/posts', {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async getMyPost(id: string | number) {
    const response = await axios.get(`/api/v1/posts/${id}`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async updateMyPost(post: IPost) {
    const response = await axios.put(`/api/v1/posts/${post.id}`, post, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async deleteMyPost(id: string | number) {
    const response = await axios.delete(`/api/v1/posts/${id}`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async clonePost(id: number) {
    const response = await axios.put(`/api/v1/posts/${id}/clone`, null, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }
}

export default PostMethods
