























































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import { IAlerts } from '@/interfaces'
import $ from 'jquery'
import { IAlert } from '@/types'

@Component
export default class Alerts extends Vue {
  @Prop(Object) readonly alert!: IAlert

  alerts: IAlerts = {
    alertMessage: '',
    warningAlert: false,
    infoAlert: false,
    successAlert: false,
    dangerAlert: false,
  }

  clearAlerts() {
    this.alerts = {
      alertMessage: '',
      warningAlert: false,
      infoAlert: false,
      successAlert: false,
      dangerAlert: false,
    }
    this.resetAlertProps()
  }

  timeoutDefault() {
    let timeOut
    switch (this.alert.alertType) {
      case 'info':
      case 'warning':
      case 'danger':
        timeOut = 60000
        break
      case 'success':
        timeOut = 5000
        break
    }
    return timeOut
  }

  @Emit()
  resetAlertProps() {}

  @Watch('alert')
  onMessageChange() {
    if (this.alert.message !== null) {
      this.alerts.alertMessage = this.alert.message
      switch (this.alert.alertType) {
        case 'info':
          this.alerts.infoAlert = true
          break
        case 'warning':
          this.alerts.warningAlert = true
          break
        case 'success':
          this.alerts.successAlert = true
          break
        case 'danger':
          this.alerts.dangerAlert = true
          break
      }

      setTimeout(() => {
        $('.alert-close-btn').click()
      }, this.alert.timeout || this.timeoutDefault())
    }
  }
}
