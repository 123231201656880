






















































































































































import { IAlert } from '@/types'
import { IPost } from '@/_store/models'
import { EnvironmentModule } from '@/_store/modules'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters(['StateEditPost', 'StateUser']),
  },
})
export default class SharePostModal extends Vue {
  @Prop()
  sendAlertMessageUp!: Function

  StateEditPost!: IPost

  sendingEmail = false

  get post() {
    return this.StateEditPost
  }

  get embedCode() {
    let id: string | number = ''
    switch (this.post.visibility) {
      case 'clubs_only':
        id = this.post.embed_id ? this.post.embed_id : ''
        break
      case 'public':
        id = this.post.id
        break
    }
    /* eslint-disable-next-line no-useless-escape */
    return `<script data-mxie-id="${id}" type="text/javascript" src="${EnvironmentModule.widgetUrl}/w.mixonium.js" async><\/script>`
  }

  get postImage() {
    return this.post.social_thumb_600px
  }

  get postUrl() {
    let url = ''
    switch (this.post.visibility) {
      case 'clubs_only':
        url = `${EnvironmentModule.siteUrl}/clubs/post/${this.post.embed_id}`
        break
      case 'public':
        url = `${EnvironmentModule.siteUrl}/public/post/${this.post.id}`
        break
    }
    return url
  }

  get shareId() {
    let id: string | number = this.post.id
    if (this.post.visibility === 'clubs_only' && this.post.embed_id) {
      id = this.post.embed_id
    }
    return id
  }

  get twitterUrlShare() {
    const link = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      this.postUrl
    )}`
    return link
  }

  get facebookUrlShare() {
    const link = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      this.postUrl
    )}`
    return link
  }

  get isMobile() {
    const yes = this.$isMobile()
    return yes
  }

  async sendPostEmail() {
    this.sendingEmail = true
    const response = await this.$api.sendPostEmail(this.post)
    if (response.status === 200) {
      const alert: IAlert = {
        message: `An email with this post has been sent to your email.<br>Use your mail client to edit and forward as desired!`,
        alertType: 'success',
        timeout: 10000,
      }
      this.sendingEmail = false
      this.sendAlertMessageUp(alert)
    } else {
      const alert: IAlert = {
        message: `An error occured when sending email with this post has been sent to your email.<br>Please contact support!`,
        alertType: 'danger',
        timeout: 10000,
      }
      this.sendingEmail = false
      this.sendAlertMessageUp(alert)
    }
  }
}
