import { IClubSearch } from '@/_store/models'
import axios from 'axios'

class PublicClubMethods {
  async getPublicClubs(params: IClubSearch) {
    const response = await axios.get('/api/v1/groups', {
      params: params,
    })
    return response
  }

  async getPublicClub(clubId: string | number) {
    const response = await axios.get(`/api/v1/public_clubs/${clubId}`)
    return response
  }
}

export default PublicClubMethods
