import {
  MyPosts,
  EditPost,
  ClubOrPrivatePost,
  AnalyticsList,
  PostAnalytics,
} from '@/views/users'
const UserPostRoutes = [
  {
    path: '/posts/my-posts',
    name: 'my-post',
    component: MyPosts,
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/posts/edit-post/:id',
    name: 'edit-post',
    component: EditPost,
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/posts/private/:id',
    name: 'private-post',
    component: ClubOrPrivatePost,
    meta: { requiresAuth: false, hideFooter: true },
  },
  {
    path: '/posts/analytics',
    name: 'post-analytics-list',
    component: AnalyticsList,
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/posts/analytics/:id',
    name: 'post-analytics',
    component: PostAnalytics,
    meta: { requiresAuth: true, hideFooter: true },
  },
]
export default UserPostRoutes
