import Vue from 'vue'
import Loader from '@/components/ui/Loader.vue'
import EditWidget from '@/components/ui/EditWidget.vue'
import SharePostModal from '@/components/modals/SharePostModal.vue'
import WidgetVideoViewerModal from '@/components/modals/WidgetVideoViewerModal.vue'
import ClubImgModal from '@/components/modals/ClubImgModal.vue'
import CreateClubModal from '@/components/modals/CreateClubModal.vue'
import WidgetCell from '@/components/ui/cells/WidgetCell.vue'
import SketchFabModal from '@/components/modals/SketchFabModal.vue'
import ImageViewModal from '@/components/modals/ImageViewModal.vue'
import AudioViewModal from '@/components/modals/AudioViewModal.vue'
import LockedResource from '@/components/ui/LockedResource.vue'
import RibbonOverlay from '@/components/ui/RibbonOverlay.vue'
import WidgetViewer from '@/components/ui/WidgetViewer.vue'
import PostFieldsView from '@/components/ui/PostFieldsView.vue'
import AnalyticsMap from '@/components/ui/AnalyticsMap.vue'
import ClonedPostModal from '@/components/modals/ClonedPostModal.vue'

const importComponents = () => {
  Vue.component('Loader', Loader)
  Vue.component('EditWidget', EditWidget)
  Vue.component('SharePostModal', SharePostModal)
  Vue.component('WidgetVideoViewerModal', WidgetVideoViewerModal)
  Vue.component('ClubImgModal', ClubImgModal)
  Vue.component('CreateClubModal', CreateClubModal)
  Vue.component('WidgetCell', WidgetCell)
  Vue.component('SketchFabModal', SketchFabModal)
  Vue.component('ImageViewModal', ImageViewModal)
  Vue.component('AudioViewModal', AudioViewModal)
  Vue.component('LockedResource', LockedResource)
  Vue.component('RibbonOverlay', RibbonOverlay)
  Vue.component('WidgetViewer', WidgetViewer)
  Vue.component('PostFieldsView', PostFieldsView)
  Vue.component('AnalyticsMap', AnalyticsMap)
  Vue.component('ClonedPostModal', ClonedPostModal)
}
export default importComponents
