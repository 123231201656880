







































































































































import { ISignUp } from '@/interfaces'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { EnvironmentModule } from '@/_store/modules'
import { mapActions } from 'vuex'
import router from '@/router'
import { IAlert } from '@/types'
import VueRecaptcha from 'vue-recaptcha'

@Component({
  components: {
    VueRecaptcha,
  },
  methods: {
    ...mapActions(['LogIn']),
  },
})
export default class SignUp extends Vue {
  LogIn!: Function
  user: ISignUp = {
    username: '',
    email: '',
    password: '',
    fullName: '',
    agreeToTerms: false,
  }
  errors: any[] = []
  organization = false

  recaptchaVerified = false

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  get siteUrl() {
    return EnvironmentModule.siteUrl
  }

  get recaptchaSiteKey() {
    return EnvironmentModule.recaptchaSiteKey
  }

  resetAccountName() {
    if (!this.organization) {
      delete this.user.accountName
    }
  }

  onVerify() {
    this.recaptchaVerified = true
  }

  onExpired() {
    this.recaptchaVerified = false
  }

  checkForm() {
    this.errors = []
    if (this.user.fullName == '') {
      this.errors.push('Full Name is required')
    }

    if (this.user.email == '') {
      this.errors.push('Email is required')
    }

    if (this.user.password === undefined || this.user.password === '') {
      this.errors.push('Password is required')
    }

    if (
      this.organization &&
      (this.user.accountName === undefined ||
        this.user.accountName === null ||
        this.user.accountName === '')
    ) {
      this.errors.push('Account Name required')
    }

    return this.errors.length === 0
  }

  async submit() {
    if (this.checkForm()) {
      if (!this.user.agreeToTerms || !this.recaptchaVerified) {
        return
      }

      const response = await this.$api.signUp(this.user)

      if (response.status === 200) {
        const User = new FormData()
        User.append('login', this.user.username)
        User.append('password', this.user.password)
        await this.LogIn(User)
        router.push('/')
        const alert: IAlert = {
          message: 'Account creation successful',
          alertType: 'success',
        }
        this.sendAlertMessageUp(alert)
      } else if (response.status === 406) {
        this.errors = []
        const errors = response.data.errors
        if (Array.isArray(errors)) {
          errors.forEach((err: any) => {
            const str = err.toString()
            this.errors.push(str)
          })
        } else {
          this.errors.push(response.data.errors)
        }
        this.$scrollToTop()
      } else {
        alert('There was a system error. Please contact support!')
        return
      }
    } else {
      const alert: IAlert = {
        message: 'Please fix the errors in your form',
        alertType: 'danger',
        timeout: 5000,
      }
      this.sendAlertMessageUp(alert)
      this.$scrollToTop()
    }
  }
}
