








































































import { Component, Emit, Vue } from 'vue-property-decorator'
import { Meta } from '@sophosoft/vue-meta-decorator'
import { mapActions, mapGetters } from 'vuex'
import { IClubShort, IMemberClubs } from '@/_store/models'
import $ from 'jquery'
import { IAlert } from '@/types'

@Component({
  computed: {
    ...mapGetters(['StateMyClubs', 'StateMyClubsLoading']),
  },
  methods: {
    ...mapActions(['GetMyClubs']),
  },
})
export default class MyClubs extends Vue {
  StateMyClubs!: IMemberClubs[]
  StateMyClubsLoading!: boolean
  GetMyClubs!: Function

  showCropper = false

  @Meta
  getMetaInfo() {
    return {
      title: 'My Clubs',
    }
  }

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  get loading() {
    return this.StateMyClubsLoading
  }

  get clubs() {
    return this.StateMyClubs
  }

  visibility(club: IClubShort) {
    return club.public ? 'public' : 'clubs_only'
  }

  getMyClubs() {
    return this.GetMyClubs()
  }

  showCreateClubModal() {
    const elm = $('#createClubModal')
    // @ts-ignore
    elm.modal('show')
    // We must display cropper only after modal is visible or
    // img will not display properly in cropper
    this.showCropper = true
  }

  mounted() {
    this.getMyClubs()
  }
}
