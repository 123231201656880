
































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ImageViewModal extends Vue {
  @Prop()
  readonly position!: number

  @Prop()
  readonly imageToView!: string
}
