































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { EnvironmentModule } from '@/_store/modules'
import { Meta } from '@sophosoft/vue-meta-decorator'
import { mapGetters } from 'vuex'
import { IUser, IPublicClub } from '@/_store/models'
import { KeenFilter } from '@/interfaces'

@Component({
  computed: {
    ...mapGetters(['StateUser']),
  },
  data() {
    return {
      club: {},
    }
  },
})
export default class PublicViewClub extends Vue {
  @Meta
  getMetaInfo() {
    return {
      title: `MIXONIUM | ${this.club.name}`,
      meta: [
        // opengraph
        { property: 'og:title', content: this.club.name },
        { property: 'og:type', content: 'article' },
        {
          property: 'og:url',
          content: `${EnvironmentModule.siteUrl}/public/post/${this.clubId}`,
        },
        { property: 'og:image', content: this.club.thumbnail },
        { property: 'og:description', content: this.club.name },
        // twitter
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:site', content: '@MIXONIUM_GHI' },
        { property: 'twitter:title', content: this.club.name },
        {
          property: 'twitter:description',
          content: this.club.name,
        },
        { property: 'twitter:image', content: this.club.thumbnail },
      ],
    }
  }

  @Watch('$route.params.id')
  async onRouteChanged() {
    this.loading = true
    await this.startItUp()
  }

  StateUser!: IUser
  club!: IPublicClub
  loading = true
  locked = false
  clubViews = 0
  clubPostViews = 0

  get clubId() {
    return this.$route.params.id
  }

  get isMobile() {
    return this.$isMobile()
  }

  unlockPost() {
    this.locked = false
  }

  setLock() {
    this.locked = this.club ? this.club.password_protected : false
  }

  async trackView() {
    await this.$keenApi.trackPostOrClubEvent(
      'Club Views',
      this.$route.path,
      this.clubId,
      this.club.name
    )
  }

  async getClubViews() {
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'eq',
        property_value: this.clubId,
      },
    ]
    const resp = await this.$keenApi.analysis(
      'count',
      'Club Views',
      'this_10_years',
      null,
      'id',
      filter
    )
    this.clubViews = resp ? resp : 0
  }

  async getClubPostViews(posts: any[]) {
    const postIds = posts.map(post => post.id)
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'in',
        property_value: postIds,
      },
    ]
    await this.$keenApi
      .analysis('count', 'Post Loaded', 'this_10_years', 'id', 'id', filter)
      .then((resp: any) => {
        resp.map((obj: any) => (this.clubPostViews += obj.result))
      })
  }

  async getClub() {
    const { data } = await this.$api.getPublicClub(this.clubId)
    await this.getClubViews()
    if (data.posts?.length > 0) {
      this.getClubPostViews(data.posts)
    }
    this.club = data
    this.loading = false
  }

  async startItUp() {
    await this.getClub()
    this.setLock()
    await this.trackView()
  }

  async mounted() {
    await this.startItUp()
  }
}
