import { render, staticRenderFns } from "./WidgetVideoViewerModal.vue?vue&type=template&id=5354b473&scoped=true&"
import script from "./WidgetVideoViewerModal.vue?vue&type=script&lang=ts&"
export * from "./WidgetVideoViewerModal.vue?vue&type=script&lang=ts&"
import style0 from "./WidgetVideoViewerModal.vue?vue&type=style&index=0&id=5354b473&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5354b473",
  null
  
)

export default component.exports