import { ISignUp } from '@/interfaces'
import { IUserLogin } from '@/_store/models'
import axios from 'axios'

class PublicUserMethods {
  // Public User
  async signUp(user: ISignUp) {
    const response = await axios.post(
      `/api/v1/users`,
      { user: user },
      {
        validateStatus: function(status) {
          return status < 500 // Resolve only if the status code is less than 500
        },
      }
    )
    return response
  }

  async loginUser(user: IUserLogin) {
    const response = await axios.post(
      '/api/v1/sessions?v2_webclient=true',
      user,
      {
        validateStatus: function(status) {
          return status < 500 // Resolve only if the status code is less than 500
        },
      }
    )
    return response
  }
}

export default PublicUserMethods
