



















































































































































import { IClub, IPost } from '@/_store/models'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { IAlert, KeenTimeFrame } from '@/types'
import { IAnalyticsMapCoords, IPostStats, KeenFilter } from '@/interfaces'
import router from '@/router'

@Component({})
export default class ClubAnalytics extends Vue {
  club!: IClub
  clubId = this.$route.params.id
  isLoading = true
  posts: IPost[] = []
  cellViews: any[] = []
  postViews: any[] = []
  mapViewsCount: any[] = []
  postViewsCount: any[] = []
  cellViewsCount: any[] = []
  postIds: any[] = []
  filter: KeenTimeFrame = 'this_10_years'
  coordinates: IAnalyticsMapCoords[] = []
  clubViews = 0

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  get postViewsMax() {
    return Math.max(...this.postViewsCount)
  }

  get cellViewsMax() {
    return Math.max(...this.cellViewsCount)
  }

  get isMobile() {
    const yes = this.$isMobile()
    return yes
  }

  widthPercentage(max: number, current: number) {
    return (current / max) * 100
  }

  getCellsViewed(id: number) {
    const views = this.cellViews.find(obj => {
      return obj.post_id === id
    })

    return views ? views.result : 0
  }

  getPostLoaded(id: number) {
    const views = this.postViews.find(obj => {
      return obj.id === id
    })

    return views ? views.result : 0
  }

  sortPosts(val: boolean) {
    if (val) {
      const list = [...this.posts]
      this.posts = list.sort((a: any, b: any) =>
        a.created_at < b.created_at ? 1 : -1
      )
    } else {
      const popular = [...this.postViews]
      const popOrder = popular.sort((a: any, b: any) =>
        a.result > b.result ? 1 : -1
      )
      const list = [...this.posts]
      let result: any[] = []

      popOrder.forEach((key: any) => {
        var found = false
        list.filter((post: any) => {
          if (!found && post.id === key.id) {
            result.push(post)
            found = true
            return false
          } else {
            return true
          }
        })
      })
      this.posts = result.reverse()
      list.forEach((post: any) => {
        if (!this.posts.includes(post)) {
          this.posts.push(post)
        }
      })
    }
  }

  async setFilter() {
    this.isLoading = true
    this.coordinates = []
    await this.getPostEvents()
    await this.getCellEvents()
    await this.getMapEvents()
    await this.getClubViews()
    this.sortPosts(false)
    this.isLoading = false
  }

  async getPostEvents() {
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'in',
        property_value: this.postIds,
      },
    ]
    await this.$keenApi
      .analysis('count', 'Post Loaded', this.filter, 'id', 'id', filter)
      .then(async (resp: any) => {
        this.postViews = await resp
        this.postViewsCount = this.postViews.map((obj: any) => obj)
      })
      .catch((e: any) => {
        this.sendError(e)
      })
  }

  async getCellEvents() {
    const filter: KeenFilter[] = [
      {
        property_name: 'post_id',
        operator: 'in',
        property_value: this.postIds,
      },
    ]
    await this.$keenApi
      .analysis(
        'count',
        'Cell Viewed',
        this.filter,
        'post_id',
        'cell_number',
        filter
      )
      .then(async (resp: any) => {
        this.cellViews = await resp
        this.cellViewsCount = resp.map((obj: any) => obj)
      })
      .catch((e: any) => {
        this.sendError(e)
      })
  }

  markerLabel(cell: number) {
    const found: IPostStats = this.cellViews.find((view: IPostStats) => {
      return view.cell_number === cell
    })
    return found ? found.result.toString() : ''
  }

  async getMapEvents() {
    const keenProperty = ['id', 'longitude', 'latitude']
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'in',
        property_value: this.postIds,
      },
    ]
    const resp = await this.$keenApi
      .analysis(
        'extraction',
        'Post Loaded',
        this.filter,
        null,
        keenProperty,
        filter
      )
      .then(async (resp: any) => {
        return resp
      })
      .catch((e: any) => {
        this.sendError(e)
      })
    console.log(resp.length)
    if (resp.length > 0) {
      resp.forEach((coord: any) => {
        const c: IAnalyticsMapCoords = {
          label: this.markerLabel(coord.cell_number),
          lat: coord.latitude,
          lng: coord.longitude,
        }
        this.coordinates.push(c)
      })
    }
  }

  async getClubViews() {
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'eq',
        property_value: this.clubId,
      },
    ]
    const resp = await this.$keenApi.analysis(
      'count',
      'Club Views',
      'this_10_years',
      null,
      'id',
      filter
    )
    this.clubViews = resp ? resp : 0
  }

  async getClub() {
    const response = await this.$api.getClub(this.clubId)
    if (response.status === 200) {
      this.club = await response.data
      this.postIds = this.club.posts.map((post: IPost) => post.id)
      this.posts = this.club.posts
      this.setFilter()
    }
  }

  sendError(e: any) {
    const alert: IAlert = {
      message:
        'There was a system error when loading Analytics. Please contact support!',
      alertType: 'danger',
    }
    this.sendAlertMessageUp(alert)
    this.isLoading = false
    router.push('/clubs/my-clubs')
    throw e
  }

  mounted() {
    this.getClub()
  }
}
