































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GeneralFileTypes, CellPositions } from '@/constants'

@Component
export default class CellOverlay extends Vue {
  @Prop()
  position!: number

  @Prop()
  hoveredCell!: number

  @Prop()
  cellsLoading!: any[]

  @Prop()
  handleUrlDrop!: Function

  @Prop()
  isBlankCell!: boolean

  @Prop()
  currentCellLoading!: number

  @Prop()
  setSelectedMediaItem!: Function

  @Prop()
  onFileSelect!: Function

  positions = CellPositions
  index = this.positions.indexOf(this.position, 0)
  urlValue = ''
  acceptableFiletypes = GeneralFileTypes

  get isCellHovered() {
    return this.cellsLoading && this.cellsLoading.includes(this.position)
      ? false
      : this.hoveredCell == this.position
  }

  get isCellLoading() {
    return this.currentCellLoading === this.position
  }
}
