import MyPosts from './posts/MyPosts.vue'
import EditPost from './posts/EditPosts.vue'
import PreviewWidget from './posts/PreviewWidget.vue'
import AnalyticsList from './posts/AnalyticsList.vue'
import PostAnalytics from './posts/PostAnalytics.vue'
import ClubOrPrivatePost from './clubs/ClubOrPrivatePost.vue'
import MemberClubs from './clubs/MemberClubs.vue'
import MemberClubView from './clubs/MemberClubView.vue'
import MyClubs from './clubs/MyClubs.vue'
import EditMyClub from './clubs/EditMyClub.vue'
import MyProfile from './MyProfile.vue'
import ClubAnalytics from './clubs/ClubAnalytics.vue'

export {
  MyPosts,
  EditPost,
  PreviewWidget,
  ClubOrPrivatePost,
  MemberClubs,
  MemberClubView,
  MyClubs,
  EditMyClub,
  MyProfile,
  AnalyticsList,
  PostAnalytics,
  ClubAnalytics,
}
