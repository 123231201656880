import { Api } from '@/_services'
import { IClubSearch, IPostSearch } from '../models'
const API = new Api()
const state = {
  publicClubs: null,
  publicFeed: null,
  loading: true,
}
const getters = {
  StatePublicClubs: (state: any) => state.publicClubs,
  StatePublicFeed: (state: any) => state.publicFeed,
  StatePublicLoading: (state: any) => state.publicLoading,
}
const actions = {
  async GetPublicClubs({ commit }: any, params: IClubSearch) {
    commit('setLoading', true)
    commit('setPublicClubs', null)
    const response = await API.getPublicClubs(params)
    commit('setPublicClubs', response.data)
    commit('setLoading', false)
  },

  async GetPublicFeed({ commit }: any, params: IPostSearch) {
    commit('setLoading', true)
    commit('setPublicFeed', null)
    const response = await API.getPublicFeed(params)
    commit('setPublicFeed', response.data)
    commit('setLoading', false)
  },
}
const mutations = {
  setPublicClubs(state: any, publicClubs: any) {
    state.publicClubs = publicClubs
  },
  setPublicFeed(state: any, publicFeed: any) {
    state.publicFeed = publicFeed
  },

  setLoading(state: any, loading: boolean) {
    state.publicLoading = loading
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
