import store from '@/store'
import { IMyProfile } from '@/_store/models'
import axios from 'axios'

class UserMethods {
  async getMyProfile() {
    const response = await axios.get('/api/v1/users/-1/profile', {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async updateMyProfile(profile: IMyProfile) {
    const response = await axios.put(`/api/v1/users/${profile.id}`, profile, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async accountSearch(search: string) {
    const response = await axios.get(
      `/api/v1/super_admin/accounts/search?search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async searchUsers(search: string) {
    const response = await axios.get(`/api/v1/users/search`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
      params: {
        search: search,
      },
    })
    return response
  }
}

export default UserMethods
