











































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class RibbonOverlay extends Vue {
  @Prop()
  position!: number

  @Prop()
  visibility!: string

  @Prop({ default: false })
  onPost!: boolean
}
