
















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IClubCreate, IUser } from '@/_store/models'
//@ts-ignore
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { mapActions, mapGetters } from 'vuex'
import $ from 'jquery'
import { IAlert } from '@/types'
import router from '@/router'

@Component({
  components: {
    Cropper,
  },
  methods: {
    ...mapActions(['CreateClub']),
    ...mapGetters(['StateUser']),
  },
})
export default class CreateClubModal extends Vue {
  @Prop()
  sendAlertMessageUp!: Function

  StateUser!: Function
  CreateClub!: Function

  club: IClubCreate = {
    name: '',
    imageBlob: '',
    public: 1,
  }
  showCropper = false
  imageChangedEvent: string = ''
  cropperAspectRatio: string = '4/3'
  savingClub = false
  imgSrc: any
  disableSave = true
  nameError: string = ''
  checkingName = false
  nameGood = false

  get user() {
    const user: IUser = this.StateUser()
    return user
  }

  resetNameCheck() {
    this.nameGood = false
    this.checkingName = false
    this.nameError = ''
  }

  toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  async fileChangeEvent(event: any) {
    if (event === null || event === undefined) {
      return
    }
    // @ts-ignore
    const file = this.$refs.createClubInput.files[0]
    const result = await this.toBase64(file)
    this.imgSrc = result
    this.showCropper = true
    if (this.nameGood) {
      this.disableSave = false
    }
  }

  setAspectRatio() {
    return 4 / 3
  }

  async validateClubName() {
    this.checkingName = true
    const response = await this.$api.validateClubName(this.club.name)
    if (response.status !== 200) {
      this.nameError = 'Club name already in use. Please choose another.'
      this.nameGood = false
    } else {
      this.nameGood = true
    }
    this.checkingName = false
  }

  async saveClub() {
    this.savingClub = true
    // @ts-ignore
    const { canvas } = this.$refs.imgCropper.getResult()
    canvas.toBlob(async (blob: Blob) => {
      this.club.imageBlob = blob
      const formData: FormData = new FormData()
      formData.append('web_transaction', 'true')
      formData.append('name', this.club.name)
      formData.append('public', this.club.public.toString())
      formData.append('imageblob', this.club.imageBlob)

      const response = await this.$api.saveClub(formData)
      document.querySelector('.modal-backdrop')?.remove()
      const elm = $('#createClubModal')
      // @ts-ignore
      elm.modal('hide')
      const alert: IAlert = {
        message: `Club '${this.club.name} created!`,
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
      this.resetClub
      router.push(`/clubs/edit-club/${response.data.id}`)
    }, 'image/png')
  }

  resetClub() {
    this.club = {
      name: '',
      imageBlob: '',
      public: 1,
    }
    this.imgSrc = null
    this.showCropper = false
    this.resetNameCheck()
  }
}
