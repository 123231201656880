import { AdminUserView, AdminUsers } from '@/views/admin'
const AdminUserRoutes = [
  {
    path: '/admin/user/:id',
    name: 'admin-user-view',
    component: AdminUserView,
    meta: { requiresAdminAuth: true, hideFooter: true },
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: AdminUsers,
    meta: { requiresAdminAuth: true, hideFooter: true },
  },
]
export default AdminUserRoutes
