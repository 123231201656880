import { Landing, ContactUs } from '@/views/public'
import PublicNestedRouted from './public/views'
import NotFoundView from '@/views/NotFoundView.vue'
import ForbiddenView from '@/views/ForbiddenView.vue'
import SystemErrorView from '@/views/SystemErrorView.vue'

const PublicRoutes = [
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: { guest: true },
  },
  ...PublicNestedRouted,
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs,
    meta: { guest: true },
  },
  {
    path: '/403',
    component: ForbiddenView,
  },
  {
    path: '/404',
    component: NotFoundView,
  },
  {
    path: '/500',
    component: SystemErrorView,
  },
  {
    path: '*',
    component: NotFoundView,
  },
]
export default PublicRoutes
