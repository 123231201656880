import store from '@/store'
import { IClub, IClubImgCropUpdate, IClubName } from '@/_store/models'
import axios from 'axios'

class ClubMethods {
  async validateClubName(name: string) {
    const response = await axios.get(`/validate_club?name=${name}`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async getMyClubs() {
    const response = await axios.get('/api/v1/my_clubs', {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async getMemberClub(id: string | number) {
    const response = await axios.get(`/api/v1/member_clubs/${id}`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async getMyEditableClubs() {
    const response = await axios.get('/api/v1/users/my_groups', {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
      params: {
        editable: true,
      },
    })
    return response
  }

  async getClub(id: number | string) {
    const response = await axios.get(`/api/v1/my_clubs/${id}`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async saveClub(data: FormData) {
    const response = await axios.post('/api/v1/groups', data, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async updateClubName(data: IClubName) {
    const response = await axios.put(`/api/v1/groups/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async updateClubPassword(id: string | number, password: string) {
    const response = await axios.post(
      `/api/v1/groups/${id}/set_password`,
      { plain_text_password: password },
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async toogleClubPrivacy(id: string | number) {
    const response = await axios.post(
      `/api/v1/groups/${id}/toggle_privacy`,
      null,
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async toogleClubFavorite(id: string | number) {
    const response = await axios.post(
      `/api/v1/groups/${id}/toggle_favorite`,
      null,
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async changeClubPostOrder(id: string | number, order: any[]) {
    const response = await axios.post(
      `/api/v1/groups/${id}/setSortOrder`,
      { sort_orders: order },
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async updateClubPermissions(
    id: string | number,
    userId: number,
    roleTo: number
  ) {
    const response = await axios.post(
      `/api/v1/groups/${id}/update_permissions`,
      { user_id: userId, role: roleTo },
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async addClubMember(id: string | number, userId: string) {
    const response = await axios.post(
      `/api/v1/groups/${id}/addMember`,
      { user_id: userId },
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async removeClubMember(id: string | number, userId: number) {
    const response = await axios.post(
      `/api/v1/groups/${id}/removeMember`,
      { user_id: userId },
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async deleteClub(id: number | string) {
    const response = await axios.delete(`/api/v1/groups/${id}`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async replaceClubImage(data: IClubImgCropUpdate) {
    const formData: FormData = new FormData()
    formData.append('post_id', data.clubId.toString())
    formData.append('new_image', 'true')
    formData.append('imageblob', data.imageblob)
    let response
    response = await axios.put(`/api/v1/my_clubs/${data.clubId}`, formData, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async updateClub(club: IClub) {
    const response = await axios.put(
      `/api/v1/my_clubs/${club.group.id}`,
      club,
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async getMemberClubs() {
    const response = await axios.get(`/api/v1/member_clubs`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }
}

export default ClubMethods
