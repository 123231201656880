

































































































































































































import { IAccountSearch, IAccountSearchList } from '@/_store/models'
import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters(['StateAccountsList', 'StateLoading']),
  },
  methods: {
    ...mapActions(['GetAccounts']),
  },
})
export default class AdminAccounts extends Vue {
  GetAccounts!: Function
  StateLoading!: boolean
  StateAccountsList!: IAccountSearchList

  search_string: string = ''
  search_string_display: string = ''
  searched_string: boolean = false
  toPage: number = 1
  sortOptions = [
    {
      key: 'updated_at',
      value: 'Recently Updated',
    },
    {
      key: 'created_at',
      value: 'Newest',
    },
  ]
  sort_by: string = this.sortOptions[0]['key']
  post_search: IAccountSearch = {
    sort_by: this.sort_by,
    toPage: this.toPage,
    search: this.search_string,
  }

  get results() {
    return this.StateAccountsList
  }

  get accounts() {
    return this.StateAccountsList.entries
  }

  get loading() {
    return this.StateLoading
  }

  getAccounts() {
    if (this.searched_string) {
      this.search_string_display = this.search_string
    }
    return this.GetAccounts({
      sort_by: this.sort_by,
      page: this.toPage,
      search: this.search_string,
    })
  }

  search() {
    if (
      (this.search_string !== '' ||
        this.search_string !== undefined ||
        this.search_string !== null) &&
      this.search_string.length >= 4
    ) {
      this.toPage = 1
      this.searched_string = true
      this.getAccounts()
    } else {
      return
    }
  }

  subPage() {
    if (this.results.current_page === 1) {
      return
    } else {
      this.toPage = this.results.current_page -= 1
      this.getAccounts()
    }
  }

  advPage() {
    if (this.results.current_page === this.results.total_pages) {
      return
    } else {
      this.toPage = this.results.current_page += 1
      this.getAccounts()
    }
  }

  reset() {
    this.search_string = ''
    this.searched_string = false
    this.getAccounts()
  }

  goToAccount(id: number) {
    this.$router.push(`/admin/account/${id}`)
  }

  created() {
    this.getAccounts()
  }
}
