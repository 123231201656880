




/* eslint-disable no-undef */
import { IAnalyticsMapCoords } from '@/interfaces'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class AnalyticsMap extends Vue {
  @Prop()
  readonly coordinates!: IAnalyticsMapCoords[]

  markers: any[] = []

  createMarkers() {
    this.coordinates.forEach((coord: IAnalyticsMapCoords) => {
      // @ts-ignore
      const coordinate = new google.maps.LatLng(coord.lat, coord.lng)
      const markerOption = {
        position: coordinate,
        label: coord.label,
      }
      // @ts-ignore
      this.markers.push(new google.maps.Marker(markerOption))
    })
  }

  initMap() {
    // @ts-ignore
    var myLatlng = new google.maps.LatLng(40, -95)
    var mapOptions = {
      zoom: 4,
      center: myLatlng,
    }
    // @ts-ignore
    var map = new google.maps.Map(document.getElementById('map'), mapOptions)
    // @ts-ignore MarkerClusterer defined via script
    new MarkerClusterer(map, this.markers, {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    })
  }

  mounted() {
    this.createMarkers()
    this.initMap()
  }
}
