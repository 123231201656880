import {
  ClubOrPrivatePost,
  EditMyClub,
  MemberClubs,
  MemberClubView,
  MyClubs,
  ClubAnalytics,
} from '@/views/users'
const UserClubRoutes = [
  {
    path: '/clubs/post/:id',
    name: 'clubs-post',
    component: ClubOrPrivatePost,
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/clubs/member',
    name: 'clubs-member',
    component: MemberClubs,
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/clubs/view/:id',
    name: 'club-view',
    component: MemberClubView,
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/clubs',
    name: 'clubs',
    component: MyClubs,
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/clubs/edit-club/:id',
    name: 'edit-club',
    component: EditMyClub,
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/clubs/analytics/:id',
    name: 'club-analytics',
    component: ClubAnalytics,
    meta: { requiresAuth: true, hideFooter: true },
  },
]
export default UserClubRoutes
