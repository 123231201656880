import {
  PublicClubs,
  PublicFeed,
  PublicViewPost,
  PublicViewClub,
} from '@/views/public/views'
import { SignUp, Terms, PrivacyPolicy } from '@/views/public'

const PublicNestedRouted = [
  {
    path: '/public/post/:id',
    name: 'public-view-post',
    component: PublicViewPost,
    meta: { guest: true, hideFooter: true },
  },
  {
    path: '/public/clubs',
    name: 'public-clubs',
    component: PublicClubs,
    meta: { guest: true },
  },
  {
    path: '/public/feed',
    name: 'public-feed',
    component: PublicFeed,
    meta: { guest: true },
  },
  {
    path: '/public/club/:id',
    name: 'public-view-club',
    component: PublicViewClub,
    meta: { guest: true },
  },
  {
    path: '/signup',
    name: 'public-signup',
    component: SignUp,
    meta: { guest: true },
  },
  {
    path: '/terms',
    name: 'public-terms',
    component: Terms,
    meta: { guest: true },
  },
  {
    path: '/privacy',
    name: 'public-privacy-policy',
    component: PrivacyPolicy,
    meta: { guest: true },
  },
]

export default PublicNestedRouted
