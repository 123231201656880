/* eslint-disable no-unused-vars */
import { EnvironmentModule } from '@/_store/modules'

// Mail chimp
const setupMailchimpPopup = () => {
  var mailchimpConfig = {
    baseUrl: EnvironmentModule.mcUrl,
    uuid: EnvironmentModule.mcUuid,
    lid: EnvironmentModule.mcLid,
  }
  // No edits below this line are required
  var chimpPopupLoader = document.createElement('script')
  chimpPopupLoader.src =
    '//s3.amazonaws.com/downloads.mailchimp.com/js/signup-forms/popup/embed.js'
  chimpPopupLoader.setAttribute(
    'data-dojo-config',
    'usePlainJson: true, isDebug: false'
  )
  var chimpPopup = document.createElement('script')
  chimpPopup.appendChild(
    document.createTextNode(
      'require(["mojo/signup-forms/Loader"], function (L) { L.start({"baseUrl": "' +
        mailchimpConfig.baseUrl +
        '", "uuid": "' +
        mailchimpConfig.uuid +
        '", "lid": "' +
        mailchimpConfig.lid +
        '"})});'
    )
  )

  chimpPopupLoader.onload = function() {
    document.body.appendChild(chimpPopup)
  }
  document.body.appendChild(chimpPopupLoader)
}

//Recaptcha
const setRecaptcha = () => {
  const vueReCaptchaScript = document.createElement('script')
  vueReCaptchaScript.setAttribute(
    'src',
    'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
  )
  vueReCaptchaScript.async = true
  vueReCaptchaScript.defer = true
  document.head.appendChild(vueReCaptchaScript)
}

// Google MAP stuff
const setGoogleMaps = () => {
  const googleApiKey = EnvironmentModule.googleApiKey
  const googlePlacesScript = document.createElement('script')
  googlePlacesScript.setAttribute(
    'src',
    `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`
  )
  document.head.appendChild(googlePlacesScript)

  const googleMarksersScript = document.createElement('script')
  googleMarksersScript.setAttribute(
    'src',
    `https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js`
  )
  document.head.appendChild(googleMarksersScript)
}

// User voice
const setUserVoice = () => {
  const userVoiceScript = document.createElement('script')
  userVoiceScript.async = true
  userVoiceScript.setAttribute(
    'src',
    'https://widget.uservoice.com/rDBlZpzZBX0nTXdO7HlKQ.js'
  )

  document.head.appendChild(userVoiceScript)
  // delay this, doesn't need to be present immediately
  setTimeout(() => {
    let UserVoice: any
    // @ts-ignore
    UserVoice = window.UserVoice || []

    //
    // UserVoice Javascript SDK developer documentation:
    // https://www.uservoice.com/o/javascript-sdk
    //

    // Set colors
    UserVoice.push([
      'set',
      {
        accent_color: '#16a085',
        trigger_color: 'white',
        trigger_background_color: 'rgba(46, 49, 51, 0.6)',
      },
    ])

    // Identify the user and pass traits
    // To enable, replace sample data with actual user traits and uncomment the line
    UserVoice.push([
      'identify',
      {
        //email:      'john.doe@example.com', // User’s email address
        //name:       'John Doe', // User’s real name
        //created_at: 1364406966, // Unix timestamp for the date the user signed up
        //id:         123, // Optional: Unique id of the user (if set, this should not change)
        //type:       'Owner', // Optional: segment your users by type
        //account: {
        //  id:           123, // Optional: associate multiple users with a single account
        //  name:         'Acme, Co.', // Account name
        //  created_at:   1364406966, // Unix timestamp for the date the account was created
        //  monthly_rate: 9.99, // Decimal; monthly rate of the account
        //  ltv:          1495.00, // Decimal; lifetime value of the account
        //  plan:         'Enhanced' // Plan name for the account
        //}
      },
    ])

    // Add default trigger to the bottom-right corner of the window:
    UserVoice.push([
      'addTrigger',
      { mode: 'contact', trigger_position: 'bottom-right' },
    ])

    // Or, use your own custom trigger:
    //UserVoice.push(['addTrigger', '#id', { mode: 'contact' }]);

    // Autoprompt for Satisfaction and SmartVote (only displayed under certain conditions)
    UserVoice.push(['autoprompt', {}])
  }, 5000)
}

const setHeaderScripts = () => {
  // setupMailchimpPopup()
  setRecaptcha()
  setGoogleMaps()
  // setUserVoice()
}
export { setHeaderScripts }
