import Vue from 'vue'
// Bootstrap 4 stuff
import 'bootstrap'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-select/dist/css/bootstrap-select.min.css'
import 'bootstrap-select/dist/js/bootstrap-select.min.js'

// // Theme stuff
import './vendor/assets/css/socicon.css'
import './vendor/assets/css/entypo.css'
import './vendor/assets/css/theme.css'
/* 
  Above is for Wingman Bootstrap Theme and some Fontawesome
  Fontawesome is imported in main.ts;
  Below is for custom overrides and others
*/
import './vendor/assets/css/custom.css'
import './assets/css/styles.css'
import './vendor/assets/js/theme.js'
// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// @ts-ignore
import VueQuillEditor from 'vue-quill-editor'
const quillToolBarOptions = [
  { size: ['small', false, 'large', 'huge'] },
  {},
  'bold',
  'italic',
  'underline',
  'strike',
  'link',
  {},
  { color: [] },
  { background: [] },
  {},
  { indent: '-1' },
  { indent: '+1' },
  { list: 'ordered' },
  { list: 'bullet' },
  { align: [] },
  {},
  'clean',
]
import numeral from 'numeral'

Vue.filter('prettyMyNumber', function(value: number) {
  // See docs: http://numeraljs.com/
  if (value < 10000) {
    return numeral(value).format('0,0')
  } else {
    return numeral(value).format('0.00 a')
  }
})

// D3
const VueD3 = require('vue-d3')
Vue.use(VueD3)

declare module 'vue/types/vue' {
  /* eslint-disable-next-line*/
  interface Vue {
    $d3: any
  }
}

const tpImportComponents = () => {
  Vue.use(BootstrapVue)
  library.add(fas)
  library.add(fab)
  library.add(far)
  Vue.component('font-awesome-icon', FontAwesomeIcon)
  Vue.use(VueQuillEditor, { modules: { toolbar: quillToolBarOptions } })
}

export default tpImportComponents
