



























































/* eslint no-undef: "off"*/
import { Component, Emit, Vue } from 'vue-property-decorator'
import { IPost } from '@/_store/models'
import { IAlert, KeenTimeFrame } from '@/types'
import router from '@/router'
import { IAnalyticsMapCoords, IPostStats, KeenFilter } from '@/interfaces'

@Component({})
export default class PostAnalytics extends Vue {
  postId = this.$route.params.id
  isLoading = true
  post: IPost | null = null
  positions: any[] = [1, 2, 3, 4, 5, 6, 7]
  filter: KeenTimeFrame = 'this_10_years'
  allCellViews: any[] = []
  resultStats: IPostStats[] = []
  cellViewsCount: any[] = []
  mapInit = {
    center: {
      latitude: 40,
      longitude: -95,
    },
    zoom: 4,
  }
  coordinates: IAnalyticsMapCoords[] = []
  noMapResults = false
  prettyMyNumber = Vue.filter('prettyMyNumber')

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  get isMobile() {
    const yes = this.$isMobile()
    return yes
  }

  get cellViewsMax() {
    return Math.max(...this.cellViewsCount)
  }

  get widgetMaxWidthRatio() {
    // @ts-ignore
    return this.$refs.widget.$el.clientWidth / 760
  }

  async setBubbles() {
    const svg = await this.$d3.select('#widgetSvg')
    await this.positions.forEach((position: number) => {
      let currentCellViews: IPostStats | undefined = {
        ...this.allCellViews.find((cv: any) => {
          return cv.cell_number === position
        }),
      }
      /* eslint-disable valid-typeof*/
      if (typeof currentCellViews === undefined) {
        currentCellViews = {
          result: 0,
          cell_number: position,
        }
      }

      if (currentCellViews) {
        currentCellViews.radious = this.calculateRadius(
          currentCellViews.result,
          this.cellViewsMax
        )

        currentCellViews.color = this.calculateColor(currentCellViews.radious)

        switch (currentCellViews.cell_number) {
          case 1:
            currentCellViews.x = 200 * this.widgetMaxWidthRatio
            currentCellViews.y = 140 * this.widgetMaxWidthRatio
            break
          case 2:
            currentCellViews.x = 485 * this.widgetMaxWidthRatio
            currentCellViews.y = 160 * this.widgetMaxWidthRatio
            break
          case 3:
            currentCellViews.x = 675 * this.widgetMaxWidthRatio
            currentCellViews.y = 160 * this.widgetMaxWidthRatio
            break
          case 4:
            currentCellViews.x = 98 * this.widgetMaxWidthRatio
            currentCellViews.y = 365 * this.widgetMaxWidthRatio
            break
          case 5:
            currentCellViews.x = 295 * this.widgetMaxWidthRatio
            currentCellViews.y = 365 * this.widgetMaxWidthRatio
            break
          case 6:
            currentCellViews.x = 485 * this.widgetMaxWidthRatio
            currentCellViews.y = 365 * this.widgetMaxWidthRatio
            break
          case 7:
            currentCellViews.x = 675 * this.widgetMaxWidthRatio
            currentCellViews.y = 365 * this.widgetMaxWidthRatio
            break
        }

        currentCellViews.label = currentCellViews.result
          ? `${this.prettyMyNumber(currentCellViews.result)} views`
          : ''
        this.resultStats.push({ ...currentCellViews })
      }
    })
    /* Define the data for the circles */
    const elm = svg.selectAll('g myCircleText').data(this.resultStats)

    /*Create and place the "blocks" containing the circle and the text */
    const elemAppend = elm
      .enter()
      .append('g')
      .attr('transform', () => {
        return `translate(${400 * this.widgetMaxWidthRatio},${250 *
          this.widgetMaxWidthRatio})`
      })

    /*Create the circle for each block */
    elemAppend
      .append('circle')
      .attr('r', (d: IPostStats) => {
        return d.radious ? d.radious : 0
      })
      .attr('fill', (d: IPostStats) => {
        return d.color
      })

    /* Create the text for each block */
    elemAppend
      .append('text')
      .attr('dx', () => {
        return -30
      })
      .text((d: IPostStats) => {
        return d.label ? d.label : ''
      })
      .attr('fill', 'white')
      .attr('font-family', 'Verdana')

    elemAppend
      .transition()
      .duration(1000)
      .attr('transform', (d: IPostStats) => {
        return d.x && d.y ? `translate(${d.x},${d.y})` : ''
      })
  }

  async getCellEvents() {
    const filter: KeenFilter[] = [
      {
        property_name: 'post_id',
        operator: 'eq',
        property_value: this.postId,
      },
    ]
    await this.$keenApi
      .analysis(
        'count',
        'Cell Viewed',
        this.filter,
        'cell_number',
        'cell_number',
        filter
      )
      .then((resp: any) => {
        this.allCellViews = resp
        this.cellViewsCount = resp.map((obj: any) => obj.result)
      })
      .catch((e: any) => {
        const alert: IAlert = {
          message:
            'There was a system error when loading Analytics. Please contact support!',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
        this.isLoading = false
        router.push('/posts/analytics')
        throw e
      })
    this.getLocationData()
  }

  async setFilter() {
    // @ts-ignore
    const widgetSvg = this.$refs.widgetSvg
    // @ts-ignore
    while (widgetSvg.firstChild) {
      // @ts-ignore
      widgetSvg.removeChild(widgetSvg.firstChild)
    }
    this.allCellViews = []
    this.resultStats = []
    this.cellViewsCount = []
    await this.getCellEvents()
  }

  markerLabel(cell: number) {
    const found: IPostStats = this.allCellViews.find((view: IPostStats) => {
      return view.cell_number === cell
    })
    return found ? found.result.toString() : ''
  }
  async getLocationData() {
    this.coordinates = []
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'eq',
        property_value: this.postId,
      },
    ]
    const resp = await this.$keenApi
      .analysis('extraction', 'Post Loaded', this.filter, null, 'id', filter)
      .then((resp: any) => {
        return resp
      })
      .catch((e: any) => {
        const alert: IAlert = {
          message:
            'There was a system error when loading Analytics. Please contact support!',
          alertType: 'danger',
        }
        this.sendAlertMessageUp(alert)
        this.isLoading = false
        router.push('/posts/analytics')
        throw e
      })

    if (resp.length > 0) {
      resp.forEach((coord: any) => {
        /* 
        coord.latitude and coord.longitude was the old
        crappy way of doing. We are now using keen
        */
        const lat = coord.latitude
          ? coord.latitude
          : coord.ip_geo_info
          ? coord.ip_geo_info.coordinates[1]
          : undefined
        const lng = coord.longitude
          ? coord.longitude
          : coord.ip_geo_info
          ? coord.ip_geo_info.coordinates[0]
          : undefined
        const c: IAnalyticsMapCoords = {
          label: this.markerLabel(coord.cell_number),
          lat: lat,
          lng: lng,
        }
        this.coordinates.push(c)
      })
    } else {
      this.noMapResults = true
    }
    // wait for render to complete
    setTimeout(() => {
      if (!this.isMobile) {
        this.setBubbles()
      }
    }, 1000)
  }

  calculateRadius(viewCount: number, maxViewCount: number) {
    const viewCountPercentage = viewCount / maxViewCount
    if (viewCountPercentage < 0.25) {
      return 25 * this.widgetMaxWidthRatio // set min radius
    } else {
      return 65 * viewCountPercentage * this.widgetMaxWidthRatio // set based on count up to max of 75
    }
  }

  calculateColor(radius: number) {
    switch (true) {
      case radius <= 25 * this.widgetMaxWidthRatio: {
        return '#824ae3'
      }
      case radius <= 50 * this.widgetMaxWidthRatio: {
        return '#a63ba3'
      }
      case radius <= 75 * this.widgetMaxWidthRatio: {
        return '#c93164'
      }
      default: {
        return '#e72e28'
      }
    }
  }

  async getPost() {
    const response = await this.$api.getMyPost(this.postId)
    if (response.status === 200) {
      this.post = response.data
      this.isLoading = false
      await this.getCellEvents()
    }
  }

  mounted() {
    this.getPost()
  }
}
