




































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class AudioViewModal extends Vue {
  @Prop()
  readonly position!: number

  @Prop()
  readonly audioUrl!: string

  stopAudio = () => {
    const x = document.getElementById(
      `audioViewModal-${this.position}-audiodiv`
    )
    //@ts-ignore
    x.pause()
  }
}
