














































































import { IPost } from '@/_store/models'
import { EnvironmentModule } from '@/_store/modules'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PostFieldsView extends Vue {
  @Prop()
  post!: IPost

  @Prop()
  postViews!: number

  get useMailChimp() {
    return this.$useMailChimp
  }

  get postUrl() {
    let url = ''
    switch (this.post.visibility) {
      case 'public':
        url = `${EnvironmentModule.siteUrl}/public/post/${this.post.id}`
        break
      case 'clubs_only':
        url = `${EnvironmentModule.siteUrl}/clubs/post/${this.post.embed_id}`
        break
    }
    return url
  }

  get postUrlEncoded() {
    return encodeURI(`${EnvironmentModule.siteUrl}/public/post/${this.post.id}`)
  }
}
