import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
// routes
import { PublicRoutes, UserRoutes, AdminRoutes } from '@/routes'

Vue.use(VueRouter)

const routes = [...PublicRoutes, ...UserRoutes, ...AdminRoutes]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/')
  } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (store.getters.isAuthenticated && store.getters.isSiteAdmin) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

// Redirect deprecated urls to new locations
router.beforeEach((to, from, next) => {
  const regEx = /\/#\/(public_clubs|member_clubs)\/(.*)/
  const match = to.fullPath.match(regEx)
  if (match) {
    let path = '/'
    switch (match[1]) {
      case 'member_clubs':
        path = `/clubs/view/${match[2]}`
        break
      case 'public_clubs':
        path = `/public/club/${match[2]}`
        break
    }
    next(path)
  }
  next()
})

export default router
