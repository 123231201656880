import store from '@/store'
import {
  IAccount,
  IAccountSearch,
  IAccountUserFull,
  IUserSearch,
} from '@/_store/models'
import axios from 'axios'

class AdminMethods {
  async getUsers(params: IUserSearch) {
    const response = await axios.get('/api/v1/super_admin/users', {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
      params: params,
    })
    return response
  }

  async getUser(id: number) {
    const response = await axios.get(`/api/v1/super_admin/users/${id}`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async updateAccountUser(user: IAccountUserFull) {
    const response = await axios.put(
      `/api/v1/super_admin/users/${user.id}`,
      user,
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }

  async getAccounts(params: IAccountSearch) {
    const response = await axios.get('/api/v1/super_admin/accounts', {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
      params: params,
    })
    return response
  }

  async getAccount(id: number) {
    const response = await axios.get(`/api/v1/super_admin/accounts/${id}`, {
      headers: {
        Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
      },
    })
    return response
  }

  async updateAccount(account: IAccount) {
    const response = await axios.put(
      `/api/v1/super_admin/accounts/${account.id}`,
      account,
      {
        headers: {
          Authorization: `Bearer ${store.getters.StateUser.auth_token}`,
        },
      }
    )
    return response
  }
}
export default AdminMethods
