















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { IMyPostListing, IPostSearch, IUser } from '@/_store/models'
import { Meta } from '@sophosoft/vue-meta-decorator'
import { mapActions, mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters(['StateUser', 'StatePosts', 'StateLoading']),
  },
  methods: {
    ...mapActions(['GetPosts']),
  },
})
export default class MyPosts extends Vue {
  @Meta
  getMetaInfo() {
    return {
      title: 'My Posts',
    }
  }

  GetPosts!: Function
  StateUser!: IUser
  StatePosts!: IMyPostListing
  StateLoading!: string
  search_string: string = ''
  search_string_display: string = ''
  searched_string: boolean = false
  toPage: number = 1
  new_post: any
  submitted: boolean = false
  sortOptions = [
    {
      key: 'updated_at',
      value: 'Recently Updated',
    },
    {
      key: 'created_at',
      value: 'Newest',
    },
  ]
  sort_by: string = this.sortOptions[0]['key']
  post_search: IPostSearch = {
    sort_by: this.sort_by,
    toPage: this.toPage,
    search: this.search_string,
  }

  get user() {
    return this.StateUser
  }

  get posts() {
    return this.StatePosts
  }

  get loading() {
    return this.StateLoading
  }

  getPosts() {
    if (this.searched_string) {
      this.search_string_display = this.search_string
    }
    return this.GetPosts({
      sort_by: this.sort_by,
      page: this.toPage,
      search: this.search_string,
    })
  }

  search() {
    if (
      (this.search_string !== '' ||
        this.search_string !== undefined ||
        this.search_string !== null) &&
      this.search_string.length >= 4
    ) {
      this.toPage = 1
      this.searched_string = true
      this.getPosts()
    } else {
      return
    }
  }

  subPage() {
    if (this.posts.current_page === 1) {
      return
    } else {
      this.toPage = this.posts.current_page -= 1
      this.getPosts()
    }
  }

  advPage() {
    if (this.posts.current_page === this.posts.total_pages) {
      return
    } else {
      this.toPage = this.posts.current_page += 1
      this.getPosts()
    }
  }

  reset() {
    this.search_string = ''
    this.searched_string = false
    this.getPosts()
  }

  created() {
    this.getPosts()
  }
}
