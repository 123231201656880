































import { Component, Vue, Watch } from 'vue-property-decorator'
import { IPost, IPostStatus, IUser } from '@/_store/models'
import ProtectionModal from '@/components/modals/ProtectionModal.vue'
import { EnvironmentModule } from '@/_store/modules'
import { Meta } from '@sophosoft/vue-meta-decorator'
import { mapGetters } from 'vuex'
import { KeenFilter } from '@/interfaces'

@Component({
  components: { ProtectionModal },
  computed: {
    ...mapGetters(['StateUser']),
  },
  data() {
    return {
      post: {},
    }
  },
})
export default class PublicViewPost extends Vue {
  @Meta
  getMetaInfo() {
    return {
      title: `MIXONIUM | ${this.post.title}`,
      meta: [
        // opengraph
        { property: 'og:title', content: this.post.title },
        { property: 'og:type', content: 'article' },
        {
          property: 'og:url',
          content: `${EnvironmentModule.siteUrl}/public/post/${this.post.id}`,
        },
        { property: 'og:image', content: this.post.social_thumb },
        { property: 'og:description', content: this.post.post_description },
        // twitter
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:site', content: '@MIXONIUM_GHI' },
        { property: 'twitter:title', content: this.post.title },
        {
          property: 'twitter:description',
          content: this.post.post_description,
        },
        { property: 'twitter:image', content: this.post.social_thumb },
      ],
    }
  }
  post!: IPost
  StateUser!: IUser
  postStatus!: IPostStatus
  loading = true
  locked = true
  postViews = 0

  @Watch('$route.params.id')
  onRouteChanged() {
    this.loading = true
    this.startItUp()
  }

  get postId() {
    return this.$route.params.id
  }

  get isOwner() {
    return this.StateUser
      ? this.StateUser.id === this.postStatus.user_id
      : false
  }

  get pageTitle() {
    return this.post.title || 'testTitle'
  }

  setLock() {
    this.locked = this.post
      ? this.post.password_protected && this.post.password_enabled_posts
      : false
  }

  unlockPost() {
    this.locked = false
  }

  async getPostStatus() {
    const { data } = await this.$api.getPostStatus(this.postId)
    this.postStatus = data
    switch (this.postStatus.visibility) {
      case 'private':
        if (!this.isOwner) {
          this.$router.push('/403')
        } else {
          this.$router.push(`/posts/private/${this.postId}`)
        }
        return false

      case 'clubs_only':
        this.$router.push(`/clubs/post/${this.postId}`)
        return false
    }
    return true
  }

  async getPostViews(postId: string) {
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'eq',
        property_value: postId,
      },
    ]
    await this.$keenApi
      .analysis('count', 'Post Loaded', 'this_10_years', 'id', 'id', filter)
      .then((resp: any) => {
        resp.map((obj: any) => (this.postViews += obj.result))
      })
  }

  async getPost() {
    const { data } = await this.$api.getPublicPost(this.postId)
    await this.getPostViews(data.id)
    this.post = data
    this.loading = false
  }

  async trackView() {
    await this.$keenApi.trackPostOrClubEvent(
      'Post Loaded',
      this.$route.path,
      this.postId,
      this.post.title
    )
  }

  async startItUp() {
    await this.getPost()
    this.setLock()
    await this.trackView()
  }

  async mounted() {
    if (await this.getPostStatus()) {
      await this.startItUp()
    }
  }
}
