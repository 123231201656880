<template>
  <div class="main-container">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-6 mt-4" align="center">
          <img class="logo" src="@/assets/img/home/logo.png" />

          <div class="contact text-center">
            <h1>Need Help?</h1>
            <img
              alt="Image"
              src="@/assets/img/photo-man-whiteboard.jpg"
              class="rounded pb-4"
              style="max-width: 50%"
            />
            <h4>
              Click the help icon<img src="@/assets/img/home/trigger.png" />on
              the bottom right of this page to view topics and submit a question
            </h4>
            <br /><br />
            <h1 class="pb-4">Contact Us</h1>
            <h4 class="pb-4">
              If you have any questions or comments about the MIXONIUM
              platform.<br />Please contact us.<br /><br />
              <a href="mailto:mixology@mixonium.com ">mixology@mixonium.com</a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ContactUs extends Vue {}
</script>

<style></style>
