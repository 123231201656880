import { Module, VuexModule, getModule } from 'vuex-module-decorators'
import { Environment } from '../models'
import store from '../'

export interface IEnvironmentState extends Environment {}

@Module({ name: 'environment', store, dynamic: true, namespaced: true })
class EnvironmentModule extends VuexModule implements IEnvironmentState {
  siteUrl = process.env.VUE_APP_SITE_URL
  apiUrl = process.env.VUE_APP_API_URL
  useMailChimp = process.env.VUE_APP_USE_MAIL_CHIMP
  mcUrl = process.env.VUE_APP_MC_URL
  mcUuid = process.env.VUE_APP_MC_UUID
  mcLid = process.env.VUE_APP_MC_LID
  googleApiKey = process.env.VUE_APP_GOOGLE_API_KEY
  widgetUrl = process.env.VUE_APP_WIDGET_URL
  widgetJsonpUrl = process.env.VUE_APP_WIDGET_JSONP_URL
  recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY
  recaptchaSecretKey = process.env.VUE_APP_RECAPTCHA_SECRET_KEY
  keenProjectId = process.env.VUE_APP_KEEN_PROJECT_ID
  keenWriteKey = process.env.VUE_APP_KEEN_WRITE_KEY
  keenReadKey = process.env.VUE_APP_KEEN_READ_KEY
}

export default getModule(EnvironmentModule)
