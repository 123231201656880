




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ProtectionModal } from '../modals'
import { createHash } from 'crypto';

@Component({
  components: {
    ProtectionModal,
  },
})
export default class LockedResource extends Vue {
  @Prop()
  unlock!: Function

  @Prop()
  readonly resourcePassword!: string

  @Prop()
  readonly type!: string

  @Prop()
  readonly resourceId!: string

  get resourceHash() {
    return createHash('sha256').update(`${this.type}-${this.resourceId}`).digest('hex');
  }

  get isPost() {
    return this.type === 'post'
  }

  get isClub() {
    return this.type === 'club'
  }
}
