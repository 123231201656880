



























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { EnvironmentModule } from '@/_store/modules'
import { IMediaItems, IPost } from '@/_store/models'
import { MediaTypes, CellPositions } from '@/constants'

@Component
export default class WidgetViewer extends Vue {
  @Prop()
  embededId!: string | number

  @Prop()
  jsonData!: IPost

  @Prop()
  readonly isOwner!: boolean

  jsonDataForWidget = this.jsonData || null

  loading = true
  widgetUrl = EnvironmentModule.widgetUrl
  scriptElm: HTMLScriptElement | null = null
  positions = CellPositions.slice(1)
  isPortrait = false
  maybeNewPost = false

  get isMobile() {
    const yes = this.$isMobile()
    return yes
  }

  get isIpad() {
    return /iPad/i.test(navigator.userAgent)
  }

  get title() {
    return this.jsonDataForWidget?.title
  }

  needBadge(position: number) {
    const item = this.getMediaItem(position)
    return item ? item.media_type !== 0 : false
  }

  getCellAltText(position: number) {
    const item = this.getMediaItem(position)
    return item ? item.alt_text : ''
  }

  getCellTitle(position: number) {
    const item = this.getMediaItem(position)
    return item ? item.title : ''
  }

  getBadgeClass(position: number) {
    const item = this.getMediaItem(position)
    let className: string = ''
    if (item && item.media_type !== 0) {
      className = this.badgeClass(item.media_type)
    }
    return className
  }

  getMediaItem(position: number) {
    return this.jsonDataForWidget?.media_items?.find(
      (mi: IMediaItems) => mi.position === position
    )
  }

  getCellLink(position: number) {
    const item = this.getMediaItem(position)
    let link = null
    if (item && [6, 7].includes(item.media_type)) {
      switch (item.media_type) {
        case 6:
          link = item.file_url
          break
        case 7:
          link = item.url
          break
      }
    }
    return link
  }

  getMediaType(position: number) {
    const item = this.getMediaItem(position)
    return item?.media_type
  }

  getMeditItemImg(position: number) {
    let src
    const item = this.getMediaItem(position)

    if (item) {
      const imgKey = this.getPositionImgKey(position)
      src = item[imgKey]
    }
    return src
  }

  isSketchFab(position: number) {
    const item = this.getMediaItem(position)
    return item ? item.media_type === MediaTypes.MEDIA_TYPE_SKETCHFAB : false
  }

  getSketchFabEmbed(position: number) {
    const item = this.getMediaItem(position)
    let embed = null
    if (item && item.sketchfab_embed_data) {
      embed = item.sketchfab_embed_data
    }
    return embed
  }

  isItemYoutubeOrVideo(position: number) {
    const item = this.getMediaItem(position)
    return item ? [1, 4].includes(item.media_type) : false
  }

  isItemAudio(position: number) {
    const item = this.getMediaItem(position)
    return item ? item.media_type == 2 : false
  }

  getVideoUrl(position: number) {
    const item = this.getMediaItem(position)
    let url = ''
    if (item) {
      if (item.youtube_url) {
        url = `https://www.youtube.com/embed/${item.youtube_url
          .split('/')
          .pop()}`
      } else {
        url = `${item.video_url}`
      }
    }
    return url
  }

  isItemImage(position: number) {
    const item = this.getMediaItem(position)
    return item ? item.media_type === MediaTypes.MEDIA_TYPE_IMAGE : false
  }

  getImageToView(position: number) {
    const item = this.getMediaItem(position)
    let url = ''
    if (item && item.image_original_url) {
      url = item.image_original_url
    }
    return url
  }

  getAudioUrl(position: number) {
    const item = this.getMediaItem(position)
    let url = ''
    if (item && item.audio_url) {
      url = item.audio_url
    }
    return url
  }

  private badgeClass(mediaType: number) {
    let className = ''
    switch (mediaType) {
      case 0:
        className = 'image'
        break
      case 1: // video
      case 4: // youtube
        className = 'video'
        break
      case 2: // audio
      case 3: // music
        className = 'audio'
        break
      case 6: // File
        className = 'pdf'
        break
      case 7:
        className = 'link'
        break
      case 8: // maps
        className = 'map'
        break
      case 11:
        className = 'sketchfab'
        break
      default:
        break
    }

    if (className !== '') {
      className = `mxie-badge mxie-${className}`
    }
    return className
  }

  private getPositionImgKey(position: number) {
    switch (position) {
      case 1:
        return 'image_big_cell_url'
      case 2:
      case 3:
        return 'image_tall_cell_url'
      default:
        return 'image_small_cell_url'
    }
  }

  async getJsonData() {
    if (!this.jsonData) {
      const response = await this.$api.getJsonpData(this.embededId)
      if (response.status === 200) {
        this.jsonDataForWidget = JSON.parse(
          response.data
            .toString()
            .replace(`parseResponse${this.embededId}(`, '')
            .slice(0, -2)
        )
        if (
          this.jsonDataForWidget.media_items &&
          this.jsonDataForWidget.media_items.length === 0
        ) {
          this.maybeNewPost = true
        }
      } else if (response.status === 403) {
        this.maybeNewPost = true
      } else {
        alert('There was an error getting widget data! Please contact support.')
      }
    } else if (
      this.jsonData.media_items &&
      this.jsonData.media_items.length === 0
    ) {
      this.maybeNewPost = true
    }
    this.loading = false
  }

  mounted() {
    // window.addEventListener('orientationchange', this.handleOrientationChange)
    // this.handleOrientationChange()
    this.getJsonData()
  }
}
