




import { Component, Vue } from 'vue-property-decorator'
import router from '../../router'

@Component
export default class PublicView extends Vue {
  mounted() {
    router.push('/public/feed')
  }
}
