






















































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class WidgetCell extends Vue {
  @Prop()
  readonly position!: number

  @Prop()
  readonly imageSource!: number

  @Prop()
  readonly isItemYoutubeOrVideo!: boolean

  @Prop()
  readonly isItemAudio!: boolean

  @Prop()
  readonly isSketchFab!: boolean

  @Prop()
  readonly isImage!: boolean

  @Prop()
  readonly cellTitle!: string | null

  @Prop()
  readonly cellAltText!: string

  @Prop()
  readonly needBadge!: boolean

  @Prop()
  readonly badgeClass!: boolean | null

  @Prop()
  readonly cellLink!: string | null

  @Prop()
  readonly postId!: string | number

  @Prop()
  readonly positionAlt!: string

  get dataTarget() {
    let target = ''
    if (!this.cellLink) {
      if (this.isItemYoutubeOrVideo) {
        target = this.positionAlt
          ? `#widgetModalViewer-${this.positionAlt}`
          : `#widgetModalViewer-${this.position}`
      }
      if (this.isItemAudio) {
        target = this.positionAlt
          ? `#audioViewModal-${this.positionAlt}`
          : `#audioViewModal-${this.position}`
      }
      if (this.isSketchFab) {
        target = this.positionAlt
          ? `#sketchFabModal-${this.positionAlt}`
          : `#sketchFabModal-${this.position}`
      }
      if (this.isImage) {
        target = this.positionAlt
          ? `#imageViewModal-${this.positionAlt}`
          : `#imageViewModal-${this.position}`
      }
    }
    return target
  }

  get dataToggle() {
    let toggle = ''
    if (!this.cellLink) {
      if (
        this.isItemYoutubeOrVideo ||
        this.isSketchFab ||
        this.isImage ||
        this.isItemAudio
      ) {
        toggle = `modal`
      }
    }
    return toggle
  }

  async trackView() {
    await this.$keenApi.trackCellEvent(
      'Cell Viewed',
      this.position,
      this.postId
    )
  }
}
