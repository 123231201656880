import { IPostSearch } from '@/_store/models'
import axios from 'axios'

class PublicPostMethods {
  async getPostStatus(postId: string | number) {
    const response = await axios.get(`/api/v1/posts/${postId}/post_status`)
    return response
  }

  async getPublicFeed(params: IPostSearch) {
    const response = await axios.get('/api/v1/posts/public_feed?webclient=1', {
      params: params,
    })
    return response
  }

  async getPublicPost(postId: string | number) {
    const response = await axios.get(`/api/v1/posts/public/${postId}`)
    return response
  }
}

export default PublicPostMethods
