import { PreviewWidget, MyProfile } from '@/views/users'
import UserClubRoutes from './users/user_clubs'
import UserPostRoutes from './users/user_posts'
const UserRoutes = [
  ...UserPostRoutes,
  ...UserClubRoutes,
  {
    path: '/preview-widget/:id',
    name: 'preview-widget',
    component: PreviewWidget,
    meta: { requiresAuth: true, hideFooter: true },
  },
  {
    path: '/user/my-profile',
    name: 'my-profile',
    component: MyProfile,
    meta: { requiresAuth: true, hideFooter: true },
  },
]
export default UserRoutes
