









































































import router from '@/router'
import { IAlert } from '@/types'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions(['LogIn', 'SetLoggingIn']),
  },
})
export default class ModalLogin extends Vue {
  LogIn!: Function
  SetLoggingIn!: Function
  username: string = ''
  password: string = ''
  loading: boolean = false
  error: string | null = null

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  clearForm() {
    this.username = ''
    this.password = ''
    this.error = null
  }

  async loginUser() {
    this.SetLoggingIn(true)
    this.error = null
    this.loading = true
    const User = new FormData()
    User.append('login', this.username)
    User.append('password', this.password)

    const response = await this.LogIn(User)

    if (response.status === 401) {
      this.error =
        'You entered an incorrect username/password. Please try again'
      this.loading = false
    } else {
      const alert: IAlert = {
        message: 'Login Successful',
        alertType: 'success',
        timeout: 3000,
      }
      this.sendAlertMessageUp(alert)
      document.getElementById('loginModalClose')?.click()
      this.loading = false
      router.push('/posts/my-posts')
    }

    this.SetLoggingIn(false)
  }
}
