




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import $ from 'jquery'

@Component
export default class WidgetVideoViewerModal extends Vue {
  @Prop()
  readonly position!: number

  @Prop()
  readonly mediaType!: number

  @Prop()
  readonly url!: string

  get isNotYoutube() {
    return this.mediaType !== 4
  }

  mounted() {
    if (!this.isNotYoutube) {
      // when the modal is opened autoplay it
      $(`#widgetModalViewer-${this.position}`).on('shown.bs.modal', () => {
        // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
        const url = `${this.url}?autoplay=1&amp;modestbranding=1&amp;showinfo=0&amp;enablejsapi=1`
        // @ts-ignore
        const elm = document.getElementById(`video-${this.position}`)
        if (elm && elm.dataset.mixPlayed === 'false') {
          // set initial url
          // @ts-ignore
          elm.src = url
        } else {
          // use location.replace so browser history doesn't update and affect the back button
          // @ts-ignore
          elm.contentWindow.location.replace(url)
        }
      })

      // stop playing the youtube video when I close the modal
      $(`#widgetModalViewer-${this.position}`).on('hide.bs.modal', () => {
        document
          ?.getElementById(`video-${this.position}`)
          // @ts-ignore
          ?.contentWindow?.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            '*'
          )
        // @ts-ignore
        document.getElementById(`video-${this.position}`).dataset.mixPlayed =
          'true'
      })
    } else {
      $(`#widgetModalViewer-${this.position}`).on('hide.bs.modal', () => {
        // @ts-ignore
        document.getElementById(`video-${this.position}`).pause()
      })
      // when the modal is opened autoplay it
      $(`#widgetModalViewer-${this.position}`).on('shown.bs.modal', () => {
        // @ts-ignore
        document.getElementById(`video-${this.position}`).src = this.url
      })
    }
  }
}
