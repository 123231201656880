






















































































































































































































































































































































































































































































































































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { Meta } from '@sophosoft/vue-meta-decorator'
import { mapActions, mapGetters } from 'vuex'
import { IClub, IUser, IClubName } from '@/_store/models'
import { EnvironmentModule } from '@/_store/modules'
import { IAlert } from '@/types'
import { ClubMemberRoles } from '@/constants'
import $ from 'jquery'
import router from '@/router'
import { KeenFilter } from '@/interfaces'

@Component({
  computed: {
    ...mapGetters(['StateClubForEdit', 'StateLoading', 'StateUser']),
  },
  methods: {
    ...mapActions(['GetClubForEdit', 'UpdateClub', 'DeleteClub']),
  },
})
export default class EditMyClubs extends Vue {
  Roles = ClubMemberRoles
  StateClubForEdit!: IClub
  StateLoading!: boolean
  GetClubForEdit!: Function
  UpdateClub!: Function
  DeleteClub!: Function
  StateUser!: IUser

  savingName = false
  savingPassword = false
  clubId = this.$route.params.id
  postOrder = []
  currentPassword = ''
  postOrderSaving = false
  copyText = 'Copy'
  addUserValue: string = ''
  searchedUsers = []
  showCropper = false
  clubViews = 0

  @Meta
  getMetaInfo() {
    return {
      title: 'Edit My Clubs',
    }
  }

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  get loading() {
    return this.StateLoading
  }

  get club() {
    return this.StateClubForEdit
  }

  get posts() {
    return this.club.posts
  }

  get members() {
    return this.club.members
  }

  get clubUrl() {
    let url
    if (this.club.group.public) {
      url = `${EnvironmentModule.siteUrl}/public/club/${this.clubId}`
    } else {
      url = `${EnvironmentModule.siteUrl}/clubs/view/${this.clubId}`
    }
    return url
  }

  get clubEndpoint() {
    let url
    if (this.club.group.public) {
      url = `/public/club/${this.clubId}`
    } else {
      url = `/clubs/view/${this.clubId}`
    }
    return url
  }

  get user() {
    return this.StateUser
  }

  hideCropper() {
    // We must hide cropper when img changes or
    // img will not display properly in cropper
    this.showCropper = false
  }

  showClubImgModal() {
    const elm = $('#clubImgModal')
    // @ts-ignore
    elm.modal('show')
    // We must display cropper only after modal is visible or
    // img will not display properly in cropper
    this.showCropper = true
  }

  async getClub(load: boolean = true) {
    await this.GetClubForEdit([this.clubId, load])
    this.currentPassword = this.club.group.plain_text_password
    await this.getClubViews()
    const elm = $('#selectpicker')

    // refrech always or selectpicker will not show when DOM changes
    // @ts-ignore
    elm.selectpicker('refresh')
  }

  unlockName() {
    this.$el.querySelector('#nameSaveBtn')?.removeAttribute('disabled')
  }

  unlockPassword() {
    this.$el.querySelector('#passwordSaveBtn')?.removeAttribute('disabled')
  }

  async saveName() {
    this.savingName = true

    const update: IClubName = {
      id: this.club.group.id,
      name: this.club.group.name,
    }

    const checkResp = await this.$api.validateClubName(update.name)

    if (checkResp.status == 200) {
      const response = await this.$api.updateClubName(update)

      if (response.status == 200) {
        const alert: IAlert = {
          message: 'Club Name Updated',
          alertType: 'success',
        }
        this.sendAlertMessageUp(alert)
      }
    } else {
      const alert: IAlert = {
        message: `Club Name Update Failed! Name "${update.name}" already in use!`,
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }
    this.getClub(false)
    this.savingName = false
  }

  async savePassword() {
    this.savingPassword = true
    const response = await this.$api.updateClubPassword(
      this.club.group.id,
      this.club.group.plain_text_password
    )

    if (response.status == 200) {
      const alert: IAlert = {
        message: 'Club Password Updated',
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
    } else {
      const alert: IAlert = {
        message: 'Club Password Update Failed! Please Contact Support!',
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }
    this.getClub(false)
    this.savingPassword = false
  }

  async updateVis() {
    const response = await this.$api.toogleClubPrivacy(this.club.group.id)

    if (response.status == 200) {
      const alert: IAlert = {
        message: 'Club Privacy Updated',
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
    } else {
      const alert: IAlert = {
        message: 'Club Privacy Update Failed! Please Contact Support!',
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }
    this.getClub(false)
  }

  async updateFav() {
    const response = await this.$api.toogleClubFavorite(this.club.group.id)

    if (response.status == 200) {
      const alert: IAlert = {
        message: 'Club Favorited!',
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
    } else {
      const alert: IAlert = {
        message: 'Could not set club as favorite! Please Contact Support!',
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }
    this.getClub(false)
  }

  saveUrlToClip() {
    var Url = this.$refs.mylink
    // @ts-ignore
    Url.innerHTML = window.location.href
    // @ts-ignore
    Url.select()
    document.execCommand('copy')
    this.copyText = 'Copied!'
    setTimeout(() => {
      this.copyText = 'Copy'
    }, 3000)
  }

  postSortChanged(event: any) {
    this.postOrder = event.items
  }

  async updatePostOrder() {
    this.postOrderSaving = true
    const array: any[] = []
    this.postOrder.forEach((order: any) => {
      array.push({ post_id: order.item.id, sort_order: order.sort })
    })
    const response = await this.$api.changeClubPostOrder(
      this.club.group.id,
      array
    )

    if (response.status == 200) {
      const alert: IAlert = {
        message: 'Club Posts Order Saved!',
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
    } else {
      const alert: IAlert = {
        message: 'Could not save post order! Please Contact Support!',
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }
    this.postOrderSaving = false
  }

  async moveMember(id: number, roleTo: number) {
    const spinner = this.$el.querySelector(`#cont-mv-spin-${id}`)
    const btn = this.$el.querySelector(`#cont-mv-btn-${id}`)
    spinner?.classList.remove('d-none')
    btn?.setAttribute('disabled', 'true')
    await this.updatePermissions(roleTo, id)
    spinner?.classList.add('d-none')
    btn?.removeAttribute('disabled')
    this.getClub(false)
  }

  async removeMember(id: number) {
    const spinner = this.$el.querySelector(`#cont-rm-spin-${id}`)
    const btn = this.$el.querySelector(`#cont-rm-btn-${id}`)
    spinner?.classList.remove('d-none')
    btn?.setAttribute('disabled', 'true')
    await this.deleteMember(id)
    spinner?.classList.add('d-none')
    btn?.removeAttribute('disabled')
    this.getClub(false)
  }

  private async updatePermissions(roleTo: number, userId: number) {
    const response = await this.$api.updateClubPermissions(
      this.club.group.id,
      userId,
      roleTo
    )

    if (response.status == 200) {
      const alert: IAlert = {
        message: 'Member Saved!',
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
    } else {
      const alert: IAlert = {
        message: 'Could not save member! Please Contact Support!',
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }
  }

  private async deleteMember(userId: number) {
    const response = await this.$api.removeClubMember(
      this.club.group.id,
      userId
    )

    if (response.status == 200) {
      const alert: IAlert = {
        message: 'Member Removed!',
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
    } else {
      const alert: IAlert = {
        message: 'Could not remove member! Please Contact Support!',
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }
  }

  unlockAddUser() {
    const userSave = this.$el.querySelector('#user-save')
    userSave?.removeAttribute('disabled')
  }

  async addUser() {
    const userSaveSpinner = this.$el.querySelector('#user-save-spinner')
    userSaveSpinner?.classList.remove('d-none')

    const response = await this.$api.addClubMember(
      this.club.group.id,
      this.addUserValue
    )

    if (response.status == 200) {
      const alert: IAlert = {
        message: 'Member Added!',
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
    } else {
      const alert: IAlert = {
        message: 'Could not add member! Please Contact Support!',
        alertType: 'danger',
      }
      this.sendAlertMessageUp(alert)
    }

    this.addUserValue = ''
    this.searchedUsers = []
    const elm = $('#selectpicker')
    // @ts-ignore
    elm.selectpicker('refresh').empty()
    const userSave = this.$el.querySelector('#user-save')
    userSave?.setAttribute('disabled', 'true')
    userSaveSpinner?.classList.add('d-none')
    this.getClub(false)
  }

  async searchUsers() {
    // @ts-ignore
    this.addUserValue = this.$el.querySelector('#member_search input').value
    if (this.addUserValue !== '' && this.addUserValue.length > 3) {
      const response = await this.$api.searchUsers(this.addUserValue)
      if (response.data.length > 0) {
        this.searchedUsers = response.data
        const elm = $('#selectpicker')
        if (elm) {
          // @ts-ignore
          elm.selectpicker('refresh').empty()
          this.searchedUsers.forEach((obj: any) => {
            elm.append(
              '<option value="' + obj.id + '">' + obj.username + '</option>'
            )
          })
          // @ts-ignore
          elm.selectpicker('refresh')
        }
      }
    } else {
      return
    }
  }

  async getClubViews() {
    const filter: KeenFilter[] = [
      {
        property_name: 'id',
        operator: 'eq',
        property_value: this.clubId,
      },
    ]
    const resp = await this.$keenApi.analysis(
      'count',
      'Club Views',
      'this_10_years',
      null,
      'id',
      filter
    )
    this.clubViews = resp ? resp : 0
  }

  async deleteClub() {
    const del = confirm(
      `Are you sure you want to delete club '${this.club.group.name}'?\nThis is irreversible!`
    )
    if (del) {
      const name = this.club.group.name
      await this.DeleteClub(this.club.group.id)
      const alert: IAlert = {
        message: `Club '${name}' deleted!`,
        alertType: 'success',
      }
      this.sendAlertMessageUp(alert)
      router.push({ path: '/clubs' })
    }
  }

  mounted() {
    this.getClub()
  }
}
