<template>
  <div class="container">
    <section>
      <h3>MIXONIUM TERMS OF SERVICE</h3>

      <p>Effective Date: July 29, 2021</p>

      <p>
        Please read the following terms and conditions (“Terms”) carefully. They
        create a binding legal contract between you, your employees, agents and
        contractors, and any other entity on whose behalf you accept these Terms
        (collectively, “You”) and Mixonium Group Holdings, Inc. ("MIXONIUM",
        “we”, “us” or “our”) governing Your use of our website, mobile
        applications and any related features, content, products and services
        (collectively, “Services”). Among other things, these Terms describe
        Your responsibilities and limit our liability. If You do not agree to
        all of the following terms now or in the future, do not use the Services
        in any manner.
      </p>

      <p>
        By accessing the Services in any way, including by clicking the “Accept”
        (or similar) box or button, downloading or installing our mobile
        application or by creating an account, You agree to and are bound by
        these Terms. If You are using the Services on behalf of Your employer,
        You represent and warrant that You are authorized to accept these Terms
        on Your employer's behalf.
      </p>

      <p>
        Unless You have entered into another agreement with us regarding the
        Services that expressly state that the terms of that agreement control
        over these Terms, then these Terms constitute the entire agreement
        between You and MIXONIUM and govern Your use of the Services, and
        supersede any and all prior agreements, written or oral, between You and
        MIXONIUM regarding the Services (including, without limitation, any
        prior versions of these Terms). Portions of the Services may be governed
        by posted guidelines, rules or other terms and conditions. All such
        guidelines, rules and terms and conditions are hereby incorporated by
        reference into these Terms. In the event of a conflict between these
        Terms and such other guidelines, rules and terms and conditions, these
        Terms shall control. Notwithstanding the foregoing, MIXONIUM's
        <router-link to="/privacy">Privacy Policy</router-link> supersedes any
        conflicting terms in these Terms and/or any other guidelines, rules and
        terms and conditions with respect to the subject matter covered by the
        Privacy Policy.
      </p>

      <p>
        You may also be subject to additional terms and conditions that may
        apply when You use other MIXONIUM services, third-party content or
        third-party software.
      </p>

      <ol>
        <li>INFORMATION WE COLLECT</li>
        <div class="non-list">
          <p>
            <span class="underline">Eligibility</span>. To be eligible to use
            the Services, You must, and You represent and warrant that You do,
            meet the following criteria: (1) 13 years of age or older; (2) are
            not currently restricted from or otherwise prohibited from using the
            Services, (3) are not a competitor of or using the Services for
            reasons that are in competition with MIXONIUM; (4) have full power
            and authority to enter into these Terms and doing so will not
            violate any other agreement to which You are a party; (5) will not
            violate any rights of MIXONIUM, including intellectual property
            rights such as copyright or trademark rights; and (6) agree to
            provide at Your cost all equipment (including mobile devices),
            software, and internet access necessary to use the Services. Access
            or use by anyone younger than 13 years of age (or who is considered
            a minor in their jurisdiction) is not authorized.
          </p>

          <p>
            <span class="underline">Applicable Laws and this Agreement</span>.
            You agree to comply with all applicable laws, ordinances and
            regulations and these Terms.
          </p>

          <p>
            <span class="underline">Use of the Services</span>. You agree to use
            the Services only to post, send and receive content and materials
            that are proper. You further agree that You will not use the
            Services in any manner that could damage, disable, overburden, or
            impair the Services or interfere with any other party's use and
            enjoyment of them. You may not attempt to gain unauthorized access
            to any account, computer systems or networks associated with the
            Services. You may not obtain or attempt to obtain any materials or
            information through any means not intentionally made available or
            provided for through the Services.
          </p>

          <p>
            <span class="underline">User Conduct</span>. MIXONIUM does not
            control, is not responsible for and makes no representations or
            warranties with respect to any user or user conduct. You are solely
            responsible for Your interaction with or reliance on any user or
            user conduct. You must perform any necessary, appropriate, prudent
            or judicious investigation, inquiry, research and due diligence with
            respect to any user or user conduct. You further understand that You
            may be exposed to content from others that is offensive, indecent or
            objectionable.
          </p>

          <p>
            <span class="underline">Limited License</span>. MIXONIUM grants You
            a limited, revocable, non-exclusive, non-assignable,
            non-sublicensable license and right to access and use the Services
            through a generally available web browser, mobile device or
            application (but not through scraping, spidering, crawling or other
            technology or software used to access data without the express
            written consent of MIXONIUM), view information and use the Services
            that we provide in accordance with these Terms. Any other use of the
            Services is strictly prohibited. We reserve all rights not expressly
            granted in these Terms, including, without limitation, title,
            ownership, intellectual property rights, and all other rights and
            interest in the Services and all related items.
          </p>

          <p>
            <span class="underline">Promotions</span>. You agree to receive
            newsletters, promotional materials, and other communications and
            materials relating to MIXONIUM or the Services.
          </p>

          <p>
            <span class="underline">Prohibited Uses</span>. You agree that You
            will not use the Services to:
          </p>
          <ul class="terms-bullet-list">
            <li>
              pretend to be MIXONIUM or someone else, spoof MIXONIUM’s or
              someone else’s identity, or misrepresent Your affiliation with a
              person or entity;
            </li>
            <li>transmit spam, bulk or unsolicited communications;</li>
            <li>
              forge headers or otherwise manipulate identifiers (including URLs)
              in order to disguise the origin of any content transmitted through
              the Services;
            </li>
            <li>
              collect or store personal data about other users unless
              specifically authorized by such users;
            </li>
            <li>
              harvest or collect email addresses or other contact information of
              other users from the Services or MIXONIUM’s website by electronic
              or other means for the purposes of sending unsolicited emails or
              other unsolicited communications;
            </li>
            <li>
              solicit personal information from anyone under 18 or solicit
              passwords or personally identifying information for commercial or
              unlawful purposes; or
            </li>
            <li>
              register for more than one User account, register for a User
              account on behalf of another individual or on behalf of any group
              or entity.
            </li>
            <li>
              You further agree that You will not upload, post, email, transmit
              or otherwise make available any content that:
            </li>
            <li>
              is inaccurate, unlawful, threatening, abusive, harassing, obscene,
              invasive of another’s privacy, hateful, or objectionable to
              MIXONIUM or other users of the Services;
            </li>
            <li>
              contains software viruses or any other computer code, files or
              programs designed to interrupt, destroy or limit the functionality
              of any computer software or hardware or telecommunications
              equipment; or
            </li>
            <li>
              violates or infringes anyone's intellectual property or other
              rights.
            </li>
          </ul>
        </div>

        <li>USER ACCOUNTS</li>

        <div class="decimal-list">
          <div class="list-number">2.1</div>
          <p class="list-text">
            <span class="underline">Creating an Account</span>. To open an
            account, You must complete the registration process by providing us
            with true, current, complete and accurate information as prompted by
            the applicable registration form, and You will maintain and promptly
            update such information, including any authorized payment method, to
            keep it true, current, complete and accurate. You further agree that
            if You change or deactivate Your mobile telephone number, You will
            update Your account information with us within 48 hours to ensure
            that Your messages are not sent to the person who acquires Your
            former telephone number. You grant MIXONIUM the right to
            independently verify any information that You provide through the
            Services about Yourself, although MIXONIUM does not routinely
            undertake any verification.
          </p>

          <div class="decimal-list">2.2</div>
          <p class="list-text">
            <span class="underline">Activities Under Your Account</span>. You
            are responsible for maintaining the confidentiality of any
            password(s) You use to access the Services, and You are fully
            responsible for all activities that occur under Your password(s) and
            Your account. You agree to notify MIXONIUM immediately of any
            unauthorized use of Your account or any other breach of security.
            MIXONIUM will not be liable for any loss that You may incur as a
            result of someone else using Your password or account.
            Notwithstanding such notice, You could be held liable for losses
            incurred by MIXONIUM or another party due to someone else using Your
            account or password. You may not use anyone else's account at any
            time, without the permission of the account holder.
          </p>
        </div>

        <li>PAYMENTS</li>

        <div class="decimal-list">
          <div class="list-number">3.1</div>
          <p class="list-text">
            <span class="underline">Premium Services</span>. If You purchase any
            services that we offer for a fee, either on a one-time or
            subscription basis (“Premium Services”), You represent that you are
            at least 18 years of age (or the age of majority in your
            jurisdiction). In addition, You agree to allow MIXONIUM to store
            Your payment information. You also agree to pay the applicable fees
            for the Premium Services (including, without limitation, periodic
            fees for premium accounts) as they become due plus all related
            taxes, and to reimburse us for all collection costs and interest for
            any overdue amounts. Failure to pay may result in the termination of
            Your access to the applicable Premium Services. MIXONIUM does not
            support all payment methods, currencies or locations for payment. If
            the payment method You use with us, such as a credit card, reaches
            its expiration date and You do not edit Your payment method
            information or cancel Your account or such Premium Service, You
            authorize us to continue billing that payment method and You remain
            responsible for any uncollected amounts. Your obligation to pay fees
            continues through the end of the subscription period during which
            You cancel Your subscription. All applicable taxes are calculated
            based on the billing information You provide us at the time of
            purchase. You also acknowledge that MIXONIUM’s Premium Services are
            subject to these Terms and any additional terms related to the
            provision of the Premium Service.
          </p>

          <div class="list-number">3.2</div>
          <p class="list-text">
            <span class="underline">Refunds and Invoices</span>. We do not
            guarantee refunds for lack of usage or dissatisfaction.
            Additionally, if You require a printed invoice for Your transaction
            with us, You may access it through Your MIXONIUM account settings
            under “Purchase History” or request one by contacting our Customer
            Support.
          </p>

          <div class="list-number">3.3</div>
          <p class="list-text">
            <span class="underline">Third Party Fees</span>. Our prices for
            Premium Services do not include any third party fees You incur in
            connection with using a Premium Service, including, without
            limitation, fees charged by Your Internet access provider or Your
            mobile carrier (such as fees for text messaging and data charges
            will apply).
          </p>

          <div class="list-number">3.4</div>
          <p class="list-text">
            <span class="underline">Changes in Fees</span>. We may change the
            price of any Premium Services from time to time, and add new fees
            and charges for certain features or to reflect a change in business
            or legal rules, but we will send advance notice of these changes by
            email to the address in Your account information. Any increase in
            charges for the same Premium Services to which You have access at
            the time of the fee increase would not apply until the expiration of
            Your then current billing cycle, unless otherwise specifically
            provided in our notice to You, and would become effective no sooner
            than the next time You would be charged for those Premium Services.
            If You do not agree to pay the new price or other applicable
            charges, You may elect not to renew the Premium Services
            subscription before the price change goes into effect, which
            cancellation would be effective at the expiration of Your then
            current billing cycle.
          </p>

          <div class="list-number">3.5</div>
          <p class="list-text">
            <span class="underline">Autorenewal</span>. If You purchase Premium
            Services that renew automatically, such as monthly or annually, You
            agree that we may process Your payment, using the payment method You
            authorize on each monthly, annual or other renewal term (based on
            the applicable billing cycle), on the calendar day corresponding to
            the commencement of Your Premium Services. In addition, You agree
            that we have permission to retain and/or share with financial
            institutions and payment processing firms (including any
            institutions or firms we retain in the future) information regarding
            Your purchase and Your submitted payment information in order to
            process Your purchase. If Your Premium Services subscription began
            on a day not contained in a subsequent month (e.g., Your service
            began on January 30, and there is no February 30), MIXONIUM will
            process Your payment on the last day of such month. (This is what we
            mean by paying on an autorenewal basis.)
          </p>
        </div>

        <li>YOUR SUBMISSIONS</li>

        <div class="decimal-list">
          <div class="list-number">4.1</div>
          <p class="list-text">
            <span class="underline">Your Content</span>. You are solely
            responsible for all content that You upload, post or otherwise
            transmit via the Services.
          </p>

          <div class="list-number">4.2</div>
          <p class="list-text">
            <span class="underline">Ownership and License of Your Content</span
            >. MIXONIUM makes no claim to ownership of any content You provide
            to MIXONIUM. MIXONIUM shall be free to use or disseminate any
            content You make available to MIXONIUM on an unrestricted basis for
            any purpose, and You hereby grant MIXONIUM and all other users of
            the Services an irrevocable, perpetual, worldwide, transferable,
            royalty-free, fully paid up, nonexclusive license, with the right to
            grant and authorize sublicenses, to use, reproduce, modify,
            distribute and otherwise exploit such content (including in digital
            form). You agree that MIXONIUM may pass these rights along to others
            with whom MIXONIUM has contractual relationships related to the
            provision of the Services, solely for the purpose of providing such
            services. You represent and warrant that You have proper
            authorization for the worldwide transfer and processing among
            MIXONIUM and third-party providers of any content that You may
            provide to MIXONIUM. You also represent to us that, by submitting
            content to MIXONIUM and granting MIXONIUM the rights described in
            these Terms, You are not infringing the rights of any person or
            third party.
          </p>

          <div class="list-number">4.3</div>
          <p class="list-text">
            <span class="underline">Public Information</span>. Please remember
            that any information and content disclosed publicly through the
            Services becomes public information, and You should exercise caution
            when deciding to disclose Your personal information and other
            content. You may use an alias when posting personal information
            through the Services.
          </p>

          <div class="list-number">4.4</div>
          <p class="list-text">
            <span class="underline">Submissions</span>. Unless MIXONIUM has
            entered into a separate written agreement with You that explicitly
            states to the contrary, You agree that MIXONIUM can use and freely
            exploit any information, feedback, questions, comments or the like
            that You provide to MIXONIUM in connection with the Services
            (“Submissions”) on a non-confidential and non-proprietary basis and
            the Submissions will become and remain the property of MIXONIUM. No
            compensation will be paid with respect to the use of any content
            that You provide to MIXONIUM. MIXONIUM is under no obligation to
            post or use any of Your content and may remove any content or
            information at any time in its sole discretion.
          </p>

          <div class="list-number">4.5</div>
          <p class="list-text">
            <span class="underline">Indemnification</span>. You agree to
            indemnify, defend and hold harmless MIXONIUM and its affiliates, and
            their respective officers, employees, users, licensors and partners,
            from and against any claim or demand, including reasonable
            attorneys' fees, made by any third party relating to or arising out
            of Your content or Your use of the Services (including any use by
            Your employees or agents).
          </p>
        </div>

        <li>NOTICES; MODIFICATION OF TERMS AND/OR SERVICES</li>

        <div class="decimal-list">
          <div class="list-number">5.1</div>
          <p class="list-text">
            <span class="underline">Notices</span>. You agree that MIXONIUM may
            provide notice to You via email, regular mail, or posting notices or
            links to notices on MIXONIUM’s website.
          </p>

          <div class="list-number">5.2</div>
          <p class="list-text">
            <span class="underline">Modification of Terms and Services</span>.
            MIXONIUM may update or change the terms, conditions, and notices for
            the Services from time to time. You understand that MIXONIUM
            reserves the right to make these changes and that You are
            responsible for regularly reviewing these terms, conditions, and
            notices. Continued access to or use of the Services after any such
            change shall constitute Your consent to such change. Unless
            explicitly stated otherwise, any modifications to the Services will
            be subject to the Terms, as modified from time to time. MIXONIUM may
            also make changes to the Services (including content, product
            offerings and pricing) at any time and without notice. No
            modification of these Terms will be binding on MIXONIUM unless
            posted by MIXONIUM, or unless in writing and signed by a person
            authorized to act on behalf of MIXONIUM.
          </p>

          <div class="list-number">5.3</div>
          <p class="list-text">
            <span class="underline"
              >Termination or Suspension of Services; Removal of Content.</span
            >
            MIXONIUM reserves the right, in its sole discretion, to modify,
            terminate or suspend Your access to the Services or any portion
            thereof at any time and for any reason, with or without notice,
            including the removal of any content it deems to be in violation of
            these Terms. MIXONIUM will not be liable to You or any third party
            for any modification, suspension, or termination of the Services, or
            loss of related information.
          </p>
        </div>

        <li>THIRD PARTY LINKS AND TRANSACTIONS</li>

        <div class="decimal-list">
          <div class="list-number">6.1</div>
          <p class="list-text">
            MIXONIUM or its users may provide a link to other sites, including
            for advertising and promotional purposes, by allowing the user to
            leave this site to access third-party material or by bringing the
            third-party material into this site via “inverse” hyperlinks and
            framing technology (each a “Linked Website”). MIXONIUM has no
            control over the content on a Linked Website, and is not responsible
            for the operation of any Linked Website. MIXONIUM is offering these
            links to You as a convenience only, and the fact that MIXONIUM has
            provided or allowed a link to a site is not an endorsement,
            authorization, sponsorship, or affiliation with respect to such
            site, its owners, or its providers.
          </p>

          <div class="list-number">6.2</div>
          <p class="list-text">
            MIXONIUM urges You to make sure You understand the risks associated
            with using, retrieving, or relying upon any information found on the
            internet before using, retrieving, or relying upon any such
            information from a Linked Website. Your correspondence or business
            dealings with, or participation in promotions of, advertisers or
            users other than MIXONIUM found on or through our Services are
            solely between You and such advertiser or other user. MIXONIUM will
            not be responsible or liable, directly or indirectly, for any actual
            or alleged damage or loss caused by or in connection with use of or
            reliance on any such content, goods or services available on or
            through any Linked Website.
          </p>
        </div>

        <li>MIXONIUM INTELLECTUAL PROPERTY</li>

        <div class="decimal-list">
          <div class="list-number">7.1</div>
          <p class="list-text">
            All materials available through the Services and not provided by
            users of the Service are the property of MIXONIUM, affiliated
            companies and/or third-party licensors, and are protected by
            copyrights, trademarks and other intellectual property rights. All
            trademarks, service marks, and trade names are proprietary to
            MIXONIUM, or affiliated companies and/or third-party licensors.
          </p>

          <div class="list-number">7.2</div>
          <p class="list-text">
            Except as expressly authorized in writing by MIXONIUM, You agree not
            to reproduce, modify, rent, lease, loan, sell, distribute, mirror,
            frame, republish, download, transmit, or create derivative works of
            the content of others, in whole or in part, by any means. You must
            not modify, decompile, or reverse engineer any software MIXONIUM
            discloses to You. You must not remove, alter or obscure any
            copyright, patent, trademark or other proprietary or restrictive
            notice or legend contained or included in any part of the Services,
            and You shall reproduce and copy all such notices and legends on all
            copies of any part of the Services that You are permitted to make
            hereunder, if any. Any rights not expressly granted herein are
            reserved.
          </p>

          <div class="list-number">7.3</div>
          <p class="list-text">
            Any software which may be downloaded through the Services for or on
            behalf of the United States of America, its agencies and/or
            instrumentalities ("U.S. Government"), is provided with Restricted
            Rights. Use, duplication, or disclosure by the U.S. Government is
            subject to restrictions as set forth in subparagraph (c)(1)(ii) of
            the Rights in Technical Data and Computer Software clause at DFARS
            252.227-7013 or subparagraphs (c)(1) and (2) of the Commercial
            Computer Software - Restricted Rights at 48 CFR 52.227-19, as
            applicable.
          </p>
        </div>

        <li>DISCLAIMER OF WARRANTIES</li>

        <div class="decimal-list">
          <div class="list-number">8.1</div>
          <p class="list-text">
            YOUR ACCESS TO, USE OF AND RELIANCE ON THE SERVICES IS AT YOUR SOLE
            RISK UNLESS OTHERWISE EXPLICITLY STATED. THE SERVICES ARE PROVIDED
            ON AN "AS IS" "AS AVAILABLE" AND "WITH ALL FAULTS" BASIS. MIXONIUM
            DISCLAIMS ALL EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS, AND
            WARRANTIES OF ANY KIND, INCLUDING WITHOUT LIMITATION ANY IMPLIED
            WARRANTY OR CONDITION OF MERCHANTABILITY, SATISFACTORY QUALITY,
            FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT.
          </p>

          <div class="list-number">8.2</div>
          <p class="list-text">
            MIXONIUM DOES NOT ENDORSE AND IS NOT RESPONSIBLE FOR (1) THE
            ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE
            THROUGH THE SERVICES BY ANY PARTY, OR (2) ANY CONTENT PROVIDED ON OR
            CAPABILITIES OR RELIABILITY OF ANY PRODUCT OR SERVICE OBTAINED FROM
            LINKED WEBSITES.
          </p>

          <div class="list-number">8.3</div>
          <p class="list-text">
            Some jurisdictions do not allow disclaimer of implied warranties. In
            such jurisdictions, some of the foregoing disclaimer may not apply.
          </p>

          <li>LIMITATION OF LIABILITY</li>

          <div class="decimal-list">
            <div class="list-number">9.1</div>
            <p class="list-text">
              MIXONIUM SHALL NOT BE LIABLE FOR ANY ACCESS TO, USE OF OR RELIANCE
              ON THE SERVICES BY YOU OR ANYONE ELSE, OR FOR ANY TRANSACTIONS,
              COMMUNICATIONS, INTERACTIONS, DISPUTES OR RELATIONS BETWEEN YOU
              AND ANY OTHER PERSON OR ORGANIZATION ARISING OUT OF OR RELATED TO
              MIXONIUM OR CONTENT OR SERVICES ACCESSED THROUGH MIXONIUM.
            </p>

            <div class="list-number">9.2</div>
            <p class="list-text">
              YOU EXPRESSLY UNDERSTAND AND AGREE THAT MIXONIUM AND ITS
              AFFILIATES, AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, LICENSORS
              AND PARTNERS, SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE,
              INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
              INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
              GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF MIXONIUM
              HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING
              FROM: (1) THE USE OR THE INABILITY TO USE THE SERVICES; (2) THE
              COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES; (3)
              UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
              DATA; (4) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OR THROUGH
              SERVICES; OR (5) ANY OTHER MATTER RELATING TO THE SERVICES.
            </p>

            <div class="list-number">9.3</div>
            <p class="list-text">
              MIXONIUM'S AGGREGATE LIABILITY, AND THE AGGREGATE LIABILITY OF ITS
              PARTNERS AND LICENSORS, TO YOU OR ANY THIRD PARTIES IN ANY
              CIRCUMSTANCE ARISING OUT OF OR RELATING TO THE SERVICES IS LIMITED
              TO $100.
            </p>

            <div class="list-number">9.4</div>
            <p class="list-text">
              To the extent any jurisdiction does not allow the exclusion or
              limitation of direct, incidental or consequential damages,
              portions of the above limitations or exclusions may not apply.
            </p>

            <li>PRIVACY AND PERSONAL INFORMATION</li>

            <div class="decimal-list">
              <div class="list-number">10.1</div>
              <p class="list-text">
                You consent to the collection, processing and storage by
                MIXONIUM of Your personal information in accordance with the
                terms of MIXONIUM's Privacy Policy, which is available at our
                <router-link to="/privacy">Privacy Policy</router-link>. You
                agree to comply with all applicable laws and regulations, and
                the terms of MIXONIUM's Privacy Policy, with respect to any
                access, use and/or submission by You of any personal information
                in connection with this Website.
              </p>
            </div>

            <li>NOTICE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</li>

            <div class="decimal-list">
              <div class="list-number">11.1</div>
              <p class="list-text">
                MIXONIUM will investigate notices of copyright infringement and
                take appropriate actions under the Digital Millennium Copyright
                Act, Title 17, United States Code, Section 512(c)(2) ("DMCA").
                Pursuant to the DMCA, written notification of claimed copyright
                infringement must be submitted to the following Designated Agent
                for the Services:
              </p>

              <div class="list-number">11.2</div>
              <p class="list-text">
                Mixonium Group Holdings, Inc.<br />
                Attn: Copyright Review<br />
                4829 Corrales Road, Corrales NM 87048<br />
                Tel: (505)797-0300<br />
                Email: contact@MIXONIUM.com
              </p>

              <div class="list-number">11.3</div>
              <p class="list-text">
                For clarity, only DMCA notices should go to the Designated
                Agent. Any other feedback, comments, requests for technical
                support, and other communications should be directed to MIXONIUM
                customer service through contact@MIXONIUM.com.
              </p>

              <div class="list-number">11.4</div>
              <p class="list-text">
                To be effective, the notification must include the following
                (please consult Your legal counsel or see Section 512(c)(3) of
                the DMCA to confirm these requirements):
              </p>
              <ul class="terms-bullet-list">
                <li>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of an exclusive right that is
                  allegedly infringed.
                </li>
                <li>
                  Identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works at a single
                  online site are covered by a single notification, a
                  representative list of such works at that site.
                </li>
                <li>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  and information reasonably sufficient to permit the service
                  provider to locate the material. Providing URLs in the body of
                  an email is the best way to help us locate content quickly.
                </li>
                <li>
                  Information reasonably sufficient to permit the service
                  provider to contact the complaining party, such as an address,
                  telephone number, and, if available, an electronic mail
                  address at which the complaining party may be contacted.
                </li>
                <li>
                  A statement that the complaining party has a good faith belief
                  that use of the material in the manner complained of is not
                  authorized by the copyright owner, its agent, or the law.
                </li>
                <li>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that the complaining
                  party is authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.
                </li>
              </ul>
            </div>

            <p>
              Please also note that the information provided pursuant to this
              notice may be forwarded to the person who provided the allegedly
              infringing content.
            </p>

            <li>APPLICABLE LAW</li>

            <div class="decimal-list">
              <div class="list-number">12.1</div>
              <p class="list-text">
                California law and controlling U.S. federal law govern any
                action related to the Terms and/or Your use of the Services.
                Choice of law rules of any jurisdiction and the United Nations
                Convention on Contracts for the International Sale of Goods will
                not apply to any dispute under the Terms. You and MIXONIUM agree
                to submit to the personal and exclusive jurisdiction of the
                state courts located within San Francisco County, U.S.A. and the
                federal courts of the Northern District of California.
              </p>

              <div class="list-number">12.2</div>
              <p class="list-text">
                MIXONIUM makes no representation that the content available
                through the Services is appropriate for access outside of the
                United States. Those who choose to access the Services from
                outside the United States do so at their own initiative and are
                responsible for compliance with local laws.
              </p>

              <div class="list-number">12.3</div>
              <p class="list-text">
                Although the Services are accessible worldwide, they are not
                accessible to all persons or in all geographic locations.
                MIXONIUM reserves the right to limit, in its sole discretion,
                the provision and quantity of any Service to any person or
                geographic area it so desires. Any offer for any Service
                provided by MIXONIUM is void where prohibited.
              </p>
            </div>

            <li>GENERAL TERMS</li>

            <div class="decimal-list">
              <div class="list-number">13.1</div>
              <p class="list-text">
                You must not assign or otherwise transfer the Terms or any right
                granted hereunder.
              </p>

              <div class="list-number">13.2</div>
              <p class="list-text">
                You agree that any material breach of Sections 1, 2, 3, 7, 8, 10
                or 13 of the Terms will result in irreparable harm to MIXONIUM
                for which damages would be an inadequate remedy and, therefore,
                in addition to its rights and remedies otherwise available at
                law, MIXONIUM will be entitled to equitable relief, including
                both a preliminary and permanent injunction, if such a breach
                occurs. You waive any requirement for the posting of a bond or
                other security if MIXONIUM seeks such an injunction.
              </p>

              <div class="list-number">13.3</div>
              <p class="list-text">
                Services derived or obtained from MIXONIUM may be subject to the
                U.S. export laws and the export or import laws of other
                countries. You agree to comply strictly with all such laws and,
                in particular, shall: (i) obtain any export, re-export, or
                import authorizations required by U.S. or Your local laws; (ii)
                not use Services derived or obtained from MIXONIUM to design,
                develop or produce missile, chemical / biological, or nuclear
                weaponry; and (iii) not provide Services, content, or products
                derived or obtained from this Website to prohibited countries
                and entities identified in the U.S. export regulations.
              </p>

              <div class="list-number">13.4</div>
              <p class="list-text">
                Rights and obligations under the Terms which by their nature
                should survive will remain in full effect after termination or
                expiration of the Terms.
              </p>

              <div class="list-number">13.5</div>
              <p class="list-text">
                Any express waiver or failure to exercise promptly any right
                under the Terms will not create a continuing waiver or any
                expectation of non-enforcement. If any provision of the Terms is
                held invalid by any law or regulation of any government, or by
                any court or arbitrator, the parties agree that such provision
                will be replaced with a new provision that accomplishes the
                original business purpose, and the other provisions of the Terms
                will remain in full force and effect.
              </p>

              <div class="list-number">13.6</div>
              <p class="list-text">
                MIXONIUM reserves the right at all times to disclose any
                information as MIXONIUM deems necessary to satisfy any
                applicable law, regulation, legal process or governmental
                request, or to edit, refuse to post or to remove any information
                or materials, in whole or in part, in MIXONIUM's sole
                discretion.
              </p>

              <div class="list-number">13.7</div>
              <p class="list-text">
                A printed version of these Terms and of any notices given in
                electronic form shall be admissible in judicial or
                administrative proceedings based upon or relating to this
                agreement to the same extent and subject to the same conditions
                as other business documents and records originally generated and
                maintained in printed form.
              </p>
            </div>
          </div>
        </div>
      </ol>
    </section>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Terms extends Vue {}
</script>

<style scoped></style>
