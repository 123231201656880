































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import $ from 'jquery'

@Component({
  computed: {
    ...mapGetters(['StateEditPost', 'StateUser']),
  },
})
export default class SketchFabModal extends Vue {
  @Prop()
  readonly position!: number

  @Prop()
  readonly sketchFabEmbeded!: string

  removeSketchFab() {
    const elm = $(`#sketchFabDiv-${this.position}`)
    elm.empty()
    $(`#sketchFabModal-${this.position}`).on('shown.bs.modal', () => {
      elm.append(this.sketchFabEmbeded)
    })
  }

  addSketchFab() {
    // add code
    const elm = $(`#sketchFabDiv-${this.position}`)
    elm.empty()
    $(`#sketchFabModal-${this.position}`).on('shown.bs.modal', () => {
      elm.append(this.sketchFabEmbeded)
    })

    // remove embeded code
    $(`#sketchFabModal-${this.position}`).on('hide.bs.modal', () => {
      elm.empty()
    })
  }

  mounted() {
    this.addSketchFab()
  }
}
