// Keen.io
// @ts-ignore
import KeenTracking from 'keen-tracking'
// @ts-ignore
import KeenAnalysis from 'keen-analysis'
// const geoip = require('geoip-lite')
import { EnvironmentModule } from '@/_store/modules'
import { KeenAnalysisType, KeenCollectionType, KeenTimeFrame } from '@/types'
import { KeenFilter } from '@/interfaces'

export default class KeenApi {
  KeenTracker: any
  KeenAnalyze: any
  timezone = '-28800'

  constructor() {
    const { keenProjectId, keenWriteKey, keenReadKey } = EnvironmentModule
    this.KeenAnalyze = new KeenAnalysis({
      projectId: keenProjectId,
      readKey: keenReadKey,
    })

    this.KeenTracker = new KeenTracking({
      projectId: keenProjectId,
      writeKey: keenWriteKey,
    })
  }

  async analysis(
    keenType: KeenAnalysisType,
    collection: KeenCollectionType,
    timeframe: KeenTimeFrame,
    groupBy: string | null,
    property: string | any[],
    filter: KeenFilter[]
  ) {
    const data = await this.KeenAnalyze.query({
      analysisType: keenType,
      eventCollection: collection,
      timeframe: timeframe,
      groupBy: groupBy,
      timezone: this.timezone,
      target_property: property,
      filters: filter,
    })
    if (data.result.length > 0) {
      // sometimes the result will be a string instead of int
      // this is a keen bug e.g. {id: "8628", result: 42}
      // the ID value is a string not a int
      // so we check and convert as needed
      return this.convertIntObj(data.result)
    } else {
      return data.result
    }
  }

  async trackPostOrClubEvent(
    type: KeenCollectionType,
    path: string,
    id: string | number,
    title: string
  ) {
    if (['Club Views', 'Post Loaded'].includes(type)) {
      await this.KeenTracker.recordEvent(type, {
        path: path,
        platform: 'web',
        id: id,
        ...(() => {
          if (type === 'Post Loaded') {
            return {
              post_name: title,
            }
          }
        })(),
        ip_address: '${keen.ip}',
        // latitude: coords.latitude,
        // longitude: coords.longitude,
        user_agent: '${keen.user_agent}',
        keen: {
          addons: [
            {
              name: 'keen:ip_to_geo',
              input: { ip: 'ip_address' },
              output: 'ip_geo_info',
            },
          ],
        },
      }).catch((e: any) => {
        throw e
      })
    }
  }
  async trackCellEvent(
    type: KeenCollectionType,
    cell_number: string | number,
    postId: string | number
  ) {
    if (['Cell Viewed'].includes(type)) {
      await this.KeenTracker.recordEvent(type, {
        platform: 'web',
        post_id: postId,
        cell_number: cell_number,
        ip_address: '${keen.ip}',
        // latitude: coords.latitude,
        // longitude: coords.longitude,
        user_agent: '${keen.user_agent}',
        keen: {
          addons: [
            {
              name: 'keen:ip_to_geo',
              input: { ip: 'ip_address' },
              output: 'ip_geo_info',
            },
          ],
        },
      }).catch((e: any) => {
        throw e
      })
    }
  }

  private convertIntObj(obj: any) {
    const res: any = []
    for (const key in obj) {
      res[key] = {}
      for (const prop in obj[key]) {
        const parsed = parseInt(obj[key][prop], 10)
        res[key][prop] = isNaN(parsed) ? obj[key][prop] : parsed
      }
    }
    return res
  }
}
