































import { Component, Vue, Emit } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import MobileNav from './MobileNav.vue'
import DesktopNav from './DesktopNav.vue'

@Component({
  components: { MobileNav, DesktopNav },
  computed: {
    ...mapGetters(['StateUser', 'isAuthenticated', 'isSiteAdmin']),
    ...mapActions(['LogOut', 'SetLoading']),
  },
})
export default class NavBar extends Vue {
  isAuthenticated!: boolean
  isSiteAdmin!: boolean
  StateUser!: Function
  LogOut!: Function
  postCreating = false

  get user() {
    return this.StateUser
  }

  get host() {
    return window.location.host
  }

  get bannerText() {
    const host = this.host
    let title = ''
    switch (true) {
      case host === 'staging.mixonium.com':
        title = 'You are viewing the Staging Environment'
        break
      case /^localhost(:(.*)?$)/.test(host):
        title = 'You are viewing your Local Environment'
        break
    }
    return title
  }

  get bannerClass() {
    const host = this.host
    let classType = 'bg-danger'
    if (/^localhost(:(.*)?$)/.test(host)) {
      classType = 'bg-info'
    }
    return classType
  }

  get useMailChimp() {
    return this.$useMailChimp
  }

  get isLoggedIn() {
    return this.isAuthenticated
  }

  get isSuper() {
    return this.isSiteAdmin
  }

  get isMobile() {
    const yes = this.$isMobile()
    return yes
  }

  @Emit()
  async logOut() {
    await this.LogOut
  }

  async createNewPost() {
    this.postCreating = true
    const response = await this.$api.createNewPost()
    if (response.status === 200) {
      location.replace(`/posts/edit-post/${response.data.id}`)
    }
    this.postCreating = false
  }
}
