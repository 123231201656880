


































































































































































































import { Vue, Component, Emit } from 'vue-property-decorator'
import { Meta } from '@sophosoft/vue-meta-decorator'
import {
  IAccountShort,
  IAccountUserFull,
  IAccountUserUpdate,
  IUser,
} from '@/_store/models'
import { mapActions, mapGetters } from 'vuex'
import { IAlert } from '@/types'
import $ from 'jquery'

@Component({
  computed: {
    ...mapGetters(['StateLoading', 'StateUserForEdit', 'StateUser']),
  },
  methods: {
    ...mapActions(['GetUserForEdit', 'UpdateAccountUser', 'ResetUserForEdit']),
  },
})
export default class AdminUserView extends Vue {
  StateUserForEdit!: IAccountUserFull
  StateUser!: IUser
  StateLoading!: boolean
  GetUserForEdit!: Function
  UpdateAccountUser!: Function
  ResetUserForEdit!: Function

  userId = this.$route.params.id
  submitted = false

  accounts: any[] = []
  accountSearch = ''
  selectedAccount: string = ''
  selectpicker: any

  @Meta
  getMetaInfo() {
    return {
      title: 'Edit User',
    }
  }

  @Emit()
  sendAlertMessageUp(alert: IAlert) {
    return alert
  }

  get loading() {
    return this.StateLoading
  }

  get user() {
    const user = this.StateUserForEdit
    this.selectedAccount = user.account.id.toString()
    return user
  }

  async getUser() {
    await this.GetUserForEdit(this.userId)
    this.accounts = [this.user.account]
  }

  async updateAccountList() {
    // @ts-ignore
    this.accountSearch = this.$el.querySelector('#account_search input').value
    if (this.accountSearch !== '' && this.accountSearch.length > 3) {
      const response = await this.$api.accountSearch(this.accountSearch)
      if (response.data.length > 0) {
        this.accounts = response.data
        const elm = $('#selectpicker')
        if (elm) {
          // @ts-ignore
          elm.selectpicker('refresh').empty()
          this.accounts.forEach((obj: IAccountShort) => {
            elm.append(
              '<option value="' + obj.id + '">' + obj.name + '</option>'
            )
          })
          // @ts-ignore
          elm.selectpicker('refresh')
        }
      } else {
        this.accounts = [this.user.account]
      }
    } else {
      return
    }
  }

  async submit() {
    this.submitted = true
    const userUpdate: IAccountUserUpdate = {
      ...this.user,
      account_id: parseInt(this.selectedAccount),
    }

    await this.UpdateAccountUser(userUpdate)
    const alert: IAlert = {
      message: 'User Updated',
      alertType: 'success',
    }
    this.sendAlertMessageUp(alert)
    this.submitted = false
  }

  created() {
    this.getUser()
  }

  updated() {
    // @ts-ignore
    $('#selectpicker').selectpicker('refresh')
  }

  beforeDestroy() {
    this.ResetUserForEdit()
  }
}
